<template>
  <div class="w-full overflow-auto">
    <div class="flex flex-col gap-4">
      <div v-if="canHideUploadButton" class="mb-2 flex flex-col justify-between">
        <div>
          <label
            for="fileInput"
            class="border-dashed border-2 rounded-md border-gray-300 p-12 flex justify-center items-center transition-all cursor-pointer"
            :class="{ 'bg-gray-300': createNewDocument.isDragging }"
            @dragover.prevent="createNewDocument.isDragging = true"
            @dragleave="createNewDocument.isDragging = false"
            @drop.prevent="dropFiles"
          >
            <input
              id="fileInput"
              ref="file"
              type="file"
              multiple
              name="file"
              class="hidden"
              data-test="docs.fileInput"
              @change="fileInputChange"
            />
            <!-- Heroicon: outline/document-plus -->
            <div class="flex flex-col gap-2 items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-12 h-12 text-gray-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <div v-if="createNewDocument.isDragging" class="font-bold text-center">
                Release to drop files here.
              </div>
              <div v-else class="font-bold text-center" data-test="docs.dragDropText">
                Click or drop files here to upload documents.
              </div>
            </div>
          </label>
          <table
            v-if="createNewDocument.docs.length"
            class="w-full text-sm divide-y divide-gray-200"
          >
            <tbody class="">
              <tr v-for="(doc, dIndex) in createNewDocument.docs" :key="dIndex">
                <td class="whitespace-nowrap">
                  <button
                    class="py-4 px-2 text-sky-700 cursor-pointer hover:text-sky-500 hover:underline"
                    data-test="docs.viewButton"
                    @click="previewDoc(dIndex)"
                  >
                    View
                  </button>
                </td>
                <td class="whitespace-nowrap">
                  <button
                    class="py-4 px-2 text-sky-700 cursor-pointer hover:text-sky-500 hover:underline"
                    data-test="docs.deleteButton"
                    @click="deleteDoc(dIndex)"
                  >
                    Delete
                  </button>
                </td>
                <td class="py-4 px-2">
                  <div class="flex flex-row items-center gap-3" data-test="docs.fileName">
                    <label class="whitespace-nowrap">File Name:</label>
                    <div
                      class="whitespace-nowrap overflow-hidden text-ellipsis w-56"
                      :data-test="`docs.fileName-${doc.name}`"
                    >
                      {{ doc.name }}
                    </div>
                  </div>
                </td>
                <td class="p-4 whitespace-nowrap">
                  <div class="flex flex-row items-center gap-2">
                    <label for="docs">Doc Type:</label>
                    <select
                      id="docs"
                      v-model="doc.docTypeId"
                      required
                      class="focus:ring-sky-500 focus:border-sky-500 !rounded-md border border-gray-300 pl-2.5 py-1.5 flex-grow"
                      data-test="docs.docType"
                    >
                      <option
                        v-for="(item, index) in computedDocTypes"
                        :key="index"
                        :value="item.id"
                        :data-test="`docs.docType-${item}`"
                      >
                        {{ item.type }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="createNewDocument.docs.length" class="flex justify-end mt-4">
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none font-bold"
              :class="{
                'bg-deepsea-full text-white': !$isEarthquake,
                'bg-orange-100 text-orange-900': $isEarthquake,
                'opacity-60 cursor-not-allowed': disableUploadButton,
                'hover:bg-deepsea-full/75': !$isEarthquake && !disableUploadButton,
                'hover:bg-orange-200': $isEarthquake && !disableUploadButton
              }"
              :disabled="disableUploadButton"
              data-test="docs.uploadButton"
              @click="onSubmitDocuments"
            >
              <div v-if="!loading" class="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="-ml-1 mr-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                Upload
              </div>
              <svg
                v-else
                class="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div
          v-if="needsECUpload"
          data-test="ecUploadWarning"
          class="text-red-500 text-sm mt-2 flex items-center space-x-1.5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            class="w-3 flex-shrink-0"
          >
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14ZM8 4a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3A.75.75 0 0 1 8 4Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
              clip-rule="evenodd"
            />
          </svg>

          <span>An elevation certificate is required for this policy.</span>
        </div>

        <div
          v-if="needsPolicyRolloverUpload"
          class="text-red-500 text-sm mt-2 flex space-x-1.5 items-start"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            class="w-3 flex-shrink-0 mt-1"
          >
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14ZM8 4a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3A.75.75 0 0 1 8 4Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
              clip-rule="evenodd"
            />
          </svg>

          <span
            >Waiting period waived - Please upload a HUD-1 closing disclosure or settlement
            statement showing the exact date of closing matching the effective date, or a copy of
            the declaration page of other coverage showing an expiration date matching the effective
            date for roll-overs.</span
          >
        </div>
      </div>
      <p v-if="duplicateDocFlag === true" class="warning-text-color">
        You've already uploaded this document.
      </p>
      <table style="width: 100%" class="text-xs lg:text-sm uploaded">
        <tr>
          <th class="col-style-name" data-test="docsNameHeader">Name</th>
          <th class="col-style" data-test="docsModifiedHeader">Last Modified</th>
          <th v-if="!$readonly" class="col-style" data-test="docsInsuredSigHeader">
            Insured Signed
          </th>
          <th v-if="!$readonly" class="col-style" data-test="docsAgentSigHeader">Agent Signed</th>
        </tr>

        <template v-for="(folder, folderName) in allDocs">
          <!-- render folders that don't have multiple items -->
          <tr v-if="folder.length === 1 && folderName !== 'Uploads'" :key="folderName">
            <td class="!py-2">
              <!-- Do not let users get Policy or DecPage if in CA, is NB, and CAD1 has not been signed -->
              <span v-if="policyRestrictedForCalifornia(folder)">
                <div class="flex items-center space-x-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-4 h-4 text-yellow-400"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span
                    class="text-gray-600 font-medium"
                    :data-test="`docsCAD1Title-${folder[0].docName}`"
                    >{{ folder[0].docName }}</span
                  >
                </div>
                <div class="text-xs text-gray-500 ml-[22px]" data-test="docsCAD1Restriction">
                  Document access restricted until CAD1 form is signed by insured
                </div>
              </span>
              <!-- / do not let users get Policy or DecPage if in CA, is NB, and CAD1 has not been signed -->

              <!-- Also, do not let KY users get Policy or DecPage if Surplus Lines (SL) Notice hasn't been signed -->
              <span v-else-if="policyRestrictedForKentucky(folder)">
                <div class="flex items-center space-x-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-4 h-4 text-yellow-400"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span
                    class="text-gray-600 font-medium"
                    :data-test="`docsSLTitle-${folder[0].docName}`"
                    >{{ folder[0].docName }}</span
                  >
                </div>
                <div class="text-xs text-gray-500 ml-[22px]" data-test="docsSLRestriction">
                  Document access restricted until Surplus Lines Notice (SL Notice) form is signed
                  by insured
                </div>
              </span>
              <!-- /Also, do not let KY users get Policy or DecPage if Surplus Lines (SL) Notice hasn't been signed -->

              <!-- Do not let users download Application or DecPage if policy is in MA, is a type of New Business (NB) or Renewal, and BR7 has not been signed -->
              <span
                v-else-if="
                  $store.getters['policy/allData'].state === 'MA' &&
                    (originalTransactionType === 'NB' || originalTransactionType === 'RN') &&
                    !br7Signed &&
                    (folder[0].docName === 'Policy' || folder[0].docName === 'DecPage') &&
                    !$internal
                "
              >
                <div class="flex items-center space-x-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-4 h-4 text-yellow-400"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span
                    class="text-gray-600 font-medium"
                    :data-test="`docsBR7Title-${folder[0].docName}`"
                    >{{ folder[0].docName }}</span
                  >
                </div>
                <div class="text-xs text-gray-500 ml-[22px]" data-test="docsBR7Restriction">
                  Document access restricted until BR7 form is signed by insured and agent
                </div>
              </span>
              <!-- / Do not let users download Application or DecPage if policy is in MA, is New Business (NB) or Renewal, and BR7 has not been signed -->

              <a
                v-else
                class="flex cursor-pointer items-center space-x-1.5 !font-medium"
                @click="downloadRecentVersion(folder)"
              >
                <!-- checkmark -->
                <svg
                  v-if="folder[0].link"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="h-4 w-4 text-green-600"
                  :data-test="`docIcon-${folderName}`"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                    clip-rule="evenodd"
                  />
                </svg>

                <!-- /checkmark -->
                <!-- spinner -->
                <svg
                  v-else-if="!folder[0].link"
                  class="animate-spin h-5 w-5 text-gray-300"
                  data-test="profileDocSpinner"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <!-- /spinner -->

                <span
                  class="underline"
                  :class="{
                    'text-deepsea-full': $isResidential,
                    'text-brand-orange': $isEarthquake
                  }"
                  :data-test="`docLink-${folderName}`"
                >
                  {{ folder[0].docName }}
                  <span v-if="folder[0].docName === 'DecPage'"
                    >- {{ folder[0].transactionType }}</span
                  >
                </span>
              </a>
            </td>
            <td
              v-if="folder[0].createdAt"
              class="timestamp"
              :data-test="`docTimestamp-${folderName}`"
            >
              {{ folder[0].createdAt | shortDateSimpleWithTime }}
            </td>
            <td v-else class="timestamp" :data-test="`docNoTimestamp-${folderName}`">
              Not Available
            </td>
            <td
              v-if="!$readonly"
              :class="!$internal || !isPartyRequired(folderName, 2) ? 'center-icon' : ''"
            >
              <span
                v-if="!isPartyRequired(folderName, 2)"
                title="Not Required"
                class="el-icon-minus text-yellow-500 mr-2"
                :data-test="`docInsuredNoSigIcon-${folderName}`"
              />
              <span
                v-else
                class="mr-2"
                :class="{
                  'el-icon-circle-check text-green-600 text-center': isDocumentSigned(
                    folderName,
                    2
                  ),
                  'el-icon-circle-close text-red-600': !isDocumentSigned(folderName, 2)
                }"
                :data-test="`docInsuredSigIcon-${folderName}`"
              />
              <button
                v-if="isPartyRequired(folderName, 2) && $internal && !$readonly"
                class="rounded-md bg-white px-3.5 py-1.5 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-20"
                :data-test="`docInsuredSigBtn-${folderName}`"
                @click="toggleManualSign($event, folderName, 2)"
              >
                {{ isDocumentSigned(folderName, 2) ? "Unsign" : "Sign" }}
              </button>
            </td>
            <td
              v-if="!$readonly"
              :class="!$internal || !isPartyRequired(folderName, 1) ? 'center-icon' : ''"
            >
              <span
                v-if="!isPartyRequired(folderName, 1)"
                title="Not Required"
                class="el-icon-minus text-yellow-500 mr-2"
                :data-test="`docAgentNoSigIcon-${folderName}`"
              />
              <span
                v-else
                class="mr-2"
                :class="{
                  'el-icon-circle-check text-green-600 text-center': isDocumentSigned(
                    folderName,
                    1
                  ),
                  'el-icon-circle-close text-red-600': !isDocumentSigned(folderName, 1)
                }"
                :data-test="`docAgentSigIcon-${folderName}`"
              />
              <button
                v-if="isPartyRequired(folderName, 1) && $internal && !$readonly"
                class="rounded-md bg-white px-3.5 py-1.5 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-20"
                :data-test="`docAgentSigBtn-${folderName}`"
                @click="toggleManualSign($event, folderName, 1)"
              >
                {{ isDocumentSigned(folderName, 1) ? "Unsign" : "Sign" }}
              </button>
            </td>
          </tr>
          <!-- /render folders that don't have multiple items -->

          <!-- render for multiple item folders -->
          <template v-else>
            <tr :key="folderName">
              <td class="flex !py-2 cursor-pointer" @click="showVersions($event, folderName)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 mr-1"
                  :class="{ 'rotate-180': isFolderToggled(folderName) }"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                    clip-rule="evenodd"
                  />
                </svg>

                <p class="font-bold" :data-test="`docFolder-${folderName}`">{{ folderName }}</p>
              </td>
              <td class="!py-2">
                <p
                  v-if="folder[folder.length - 1].createdAt"
                  class="timestamp"
                  :data-test="`docTimestamp-${folderName}`"
                >
                  {{ folder[folder.length - 1].createdAt | shortDateSimpleWithTime }}
                </p>
                <p v-else class="timestamp" :data-test="`docNoTimestamp-${folderName}`">
                  Not Available
                </p>
              </td>
              <td
                v-if="!$readonly"
                :class="!$internal || !isPartyRequired(folderName, 2) ? 'center-icon' : ''"
              >
                <span
                  v-if="!isPartyRequired(folderName, 2)"
                  title="Not Required"
                  class="el-icon-minus text-yellow-500"
                  :data-test="`docInsuredNoSigIcon-${folderName}`"
                />
                <span
                  v-else
                  :class="{
                    'el-icon-circle-check text-green-600 text-center': isDocumentSigned(
                      folderName,
                      2
                    ),
                    'el-icon-circle-close text-red-600': !isDocumentSigned(folderName, 2)
                  }"
                  :data-test="`docInsuredSigIcon-${folderName}`"
                />

                <button
                  v-if="isPartyRequired(folderName, 2) && $internal && !$readonly"
                  class="rounded-md bg-white px-3.5 py-1.5 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-20 ml-1"
                  :data-test="`docInsuredSignBtn-${folderName}`"
                  @click="toggleManualSign($event, folderName, 2)"
                >
                  {{ isDocumentSigned(folderName, 2) ? "Unsign" : "Sign" }}
                </button>
              </td>
              <td
                v-if="!$readonly"
                :class="!$internal || !isPartyRequired(folderName, 1) ? 'center-icon' : ''"
              >
                <span
                  v-if="!isPartyRequired(folderName, 1)"
                  title="Not Required"
                  class="el-icon-minus text-yellow-500"
                  :data-test="`docAgentNoSigIcon-${folderName}`"
                />
                <span
                  v-else
                  :class="{
                    'el-icon-circle-check text-green-600 text-center': isDocumentSigned(
                      folderName,
                      1
                    ),
                    'el-icon-circle-close text-red-600': !isDocumentSigned(folderName, 1)
                  }"
                  :data-test="`docAgentSigIcon-${folderName}`"
                />
                <button
                  v-if="isPartyRequired(folderName, 1) && $internal && !$readonly"
                  class="rounded-md bg-white px-3.5 py-1.5 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-20 ml-1"
                  :data-test="`docAgentSigBtn-${folderName}`"
                  @click.prevent="toggleManualSign($event, folderName, 1)"
                >
                  {{ isDocumentSigned(folderName, 1) ? "Unsign" : "Sign" }}
                </button>
              </td>
            </tr>
            <template v-if="isFolderToggled(folderName)">
              <!-- loop through multiple files to download -->
              <tr v-for="(file, index) in folder" :key="index + `${folderName}`">
                <td class="indent flex items-center !py-2">
                  <!-- Do not let users get Policy or DecPage if in CA, is NB, and CAD1 has not been signed -->
                  <span v-if="policyRestrictedForCalifornia(folder)">
                    <div class="flex items-center space-x-1.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 h-4 text-yellow-400"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <span
                        class="text-gray-600 font-medium"
                        :data-test="`docsCAD1Title-${folder[0].docName}`"
                        >{{ folder[0].docName }}</span
                      >
                    </div>
                    <div class="text-xs text-gray-500 ml-[22px]" data-test="docsCAD1Restriction">
                      Document access restricted until CAD1 form is signed by insured
                    </div>
                  </span>
                  <!-- / do not let users get Policy or DecPage if in CA, is NB, and CAD1 has not been signed -->

                  <!-- Also, do not let KY users get Policy or DecPage if Surplus Lines (SL) Notice hasn't been signed -->
                  <span v-else-if="policyRestrictedForKentucky(folder)">
                    <div class="flex items-center space-x-1.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 h-4 text-yellow-400"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <span
                        class="text-gray-600 font-medium"
                        :data-test="`docsSLTitle-${folder[0].docName}`"
                        >{{ folder[0].docName }}</span
                      >
                    </div>
                    <div class="text-xs text-gray-500 ml-[22px]" data-test="docsSLRestriction">
                      Document access restricted until Surplus Lines Notice (SL Notice) form is
                      signed by insured
                    </div>
                  </span>
                  <!-- /Also, do not let KY users get Policy or DecPage if Surplus Lines (SL) Notice hasn't been signed -->

                  <!-- Do not let users download Application or DecPage if policy is in MA, is a type of New Business (NB) or Renewal, and BR7 has not been signed -->
                  <span
                    v-else-if="
                      $store.getters['policy/allData'].state === 'MA' &&
                        (originalTransactionType === 'NB' || originalTransactionType === 'RN') &&
                        !br7Signed &&
                        (folder[0].docName === 'Policy' || folder[0].docName === 'DecPage') &&
                        !$internal
                    "
                  >
                    <div class="flex items-center space-x-1.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 h-4 text-yellow-400"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <span
                        class="text-gray-600 font-medium"
                        :data-test="`docsBR7Title-${folder[0].docName}`"
                        >{{ folder[0].docName }}</span
                      >
                    </div>
                    <div class="text-xs text-gray-500 ml-[22px]" data-test="docsBR7Restriction">
                      Document access restricted until BR7 form is signed by insured and agent
                    </div>
                  </span>
                  <!-- / Do not let users download Application or DecPage if policy is in MA, is New Business (NB) or Renewal, and BR7 has not been signed -->
                  <div v-else class="flex">
                    <!-- trash svg -->
                    <svg
                      v-if="file.isUpload && $internal && !deleteLoading"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4 text-gray-400 cursor-pointer hover:text-gray-900 mr-1 mt-[2px]"
                      :data-test="`docs.delete-${file}`"
                      @click="deleteDocumentStart(file)"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <svg
                      v-else-if="file.isUpload && $internal && deleteLoading"
                      class="animate-spin h-5 w-5 text-gray-300 mr-1"
                      :data-test="`docs.loading-${file}`"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>

                    <a class="flex cursor-pointer !font-medium " @click="openDocument(file)">
                      <span
                        v-if="file.link"
                        class="relative top-[4px] left-[-1px] el-icon-check text-green-600"
                        :data-test="`docUploadIcon-${folderName}`"
                      />
                      <svg
                        v-else-if="!file.link"
                        class="animate-spin h-5 w-5 text-gray-300 mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>

                      <span
                        v-if="!file.isUpload"
                        class="truncate max-w-40 underline"
                        :class="{
                          'text-deepsea-full': !$isEarthquake,
                          'text-brand-orange': $isEarthquake
                        }"
                        :data-test="`docLink-${file.docName}-${folder.length - index}`"
                      >
                        {{ file.docName }} - {{ folder.length - index }}
                        <span v-if="file.docName === 'DecPage'">- {{ file.transactionType }}</span>
                      </span>
                      <span
                        v-else
                        class="truncate max-w-40 underline"
                        :class="{
                          'text-deepsea-full': !$isEarthquake,
                          'text-brand-orange': $isEarthquake
                        }"
                        :data-test="`docUploadName-${file.docName}`"
                      >
                        {{ file.docName }}
                      </span>
                    </a>
                    <div
                      v-if="$internal && showECReview(file)"
                      class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 cursor-pointer ml-2"
                      @click="toggleECReviewModal(file)"
                    >
                      Review
                    </div>
                  </div>
                </td>
                <td
                  v-if="file.createdAt"
                  class="timestamp"
                  :data-test="`docUploadTimestamp-${file.docName}-${folder.length - index}`"
                >
                  {{ file.createdAt | shortDateSimpleWithTime }}
                </td>
                <td
                  v-else
                  class="timestamp"
                  :data-test="`docUploadNoTimestamp-${file.docName}-${folder.length - index}`"
                >
                  Not Available
                </td>

                <td></td>
                <td></td>
              </tr>
            </template>
          </template>
          <!-- /render for multiple item folders -->
        </template>
      </table>
    </div>
    <el-dialog
      v-loading="loading"
      :visible.sync="showDeleteModal"
      style="z-index: 1"
      :append-to-body="true"
      title="Delete"
      width="30%"
      custom-class="dialog-body"
    >
      Are you sure you wish to delete this document?
      <span slot="footer" class="dialog-footer">
        <button
          data-test="docDeleteCancel"
          class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-sm mr-2 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium"
          @click="closeDeleteModal"
        >
          Cancel
        </button>
        <el-button
          class="!bg-deepsea-full !text-white hover:!bg-deepsea-full/75"
          data-test="docDeleteConfirm"
          @click="deleteDocumentSubmit(selectedToDelete)"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { DateFiltersMixin, StringFilters } from "../../policy-wizard/utils/mixins/formatting";
import { Constants } from "@/config";
import { authAxios } from "@/store/modules/auth";
import { mapActions } from "vuex";
import docs from "@/utils/documents/docTypes";

// import Swal from "sweetalert2";
export default {
  mixins: [DateFiltersMixin, StringFilters],
  data() {
    return {
      showReviewModal: false,
      matchedOCR: null,
      unsigned: [],
      allDocs: {},
      folderToggleState: {},
      filteredSigned: [],
      SDFileList: [],
      maxFileAlert: false,
      duplicateDocFlag: false,
      ECFileList: [],
      docNameCheck: [],
      showDeleteModal: false,
      showModal: false,
      loading: false,
      deleteLoading: false,
      createNewDocument: {
        isDragging: false,
        open: false,
        docs: []
      }
    };
  },
  computed: {
    computedDocTypes() {
      if (this.$store?.state?.policy?.confirmationData?.state === "NY") {
        return docs.nyDocTypesArray;
      } else return docs.docTypesArray;
    },
    policyNo() {
      return this.$store.getters.policyNo;
    },
    disableUploadButton() {
      return (
        !this.createNewDocument.docs.length ||
        this.createNewDocument.docs.some((d) => !d.docTypeId || !d.name)
      );
    },
    cad1Signed() {
      return !this.unsignedDocs.some(({ document }) => document.docName === "CAD1");
    },
    br7Signed() {
      return !this.unsignedDocs.some(({ document }) => document.docName === "BR7");
    },
    surplusLinesNoticeSigned() {
      return !this.unsignedDocs.some(({ document }) => document?.docName === "SL Notice");
    },
    originalTransactionType() {
      return this.$store.state?.policy?.transactionSummary?.originalTransType;
    },
    unsignedDocs() {
      return this.$store.getters["document/unsignedDocs"];
    },
    signedDocs() {
      return this.$store.getters["document/documentsWithSignatures"];
    },
    allData() {
      return this.$store.getters["policy/allData"];
    },
    canHideUploadButton() {
      const { isPendingCancel, isPolicyCancelled } = this.allData;
      if ((isPendingCancel || isPolicyCancelled) && !this.$internal) {
        return false;
      } else {
        return true;
      }
    },
    headerInfo() {
      return {
        Authorization: this.$store.state.auth.token
      };
    },
    needsECUpload() {
      // EC Doc is 4
      const uploadCheck = this.allDocs?.Uploads;
      const docTypes = uploadCheck?.map((x) => x.docType);
      return !docTypes?.includes(4) && this.allData?.ecElevation > 0;
    },
    needsPolicyRolloverUpload() {
      // EC Doc is 21
      const uploadCheck = this.allDocs?.Uploads;
      const docTypes = uploadCheck?.map((x) => x.docType);

      // Get the inception date from your store
      const inceptionDateString = this.$store.getters["policy/allData"].inceptionDate;

      // Create Date objects for inception date and today
      const inceptionDate = new Date(inceptionDateString);
      const today = new Date();

      // Calculate the difference in milliseconds
      const differenceInTime = today.getTime() - inceptionDate.getTime();

      // Convert the difference to days
      const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

      // Check if the difference is less than 60 days
      const isLessThan60Days = differenceInDays < 60;

      return (
        !docTypes?.includes(21) &&
        !docTypes?.includes(3) &&
        this.$store?.getters["policy/allData"]?.hasImmediateClosing &&
        isLessThan60Days
      );
    }
  },
  mounted() {
    this.init();
    this.$root.$on("docSuccess", () => {
      this.docSuccess();
    });
    this.$root.$on("docError", () => {
      this.docError();
    });
  },
  methods: {
    policyRestrictedForCalifornia(folder) {
      return (
        this.$store.getters["policy/allData"].state === "CA" &&
        this.originalTransactionType === "NB" &&
        !this.cad1Signed &&
        (folder[0].docName === "Policy" || folder[0].docName === "DecPage") &&
        !this.$internal
      );
    },
    policyRestrictedForKentucky(folder) {
      return (
        this.$store.getters["policy/allData"].state === "KY" &&
        this.originalTransactionType === "NB" &&
        !this.surplusLinesNoticeSigned &&
        (folder[0].docName === "Policy" || folder[0].docName === "DecPage") &&
        !this.$internal
      );
    },
    toggleECReviewModal(file) {
      const ocrDocs = this.$store.state?.document?.ocrDocs;
      for (const doc of ocrDocs) {
        if (file.policyDocVerId === doc.policyDocVerId) this.matchedOCR = doc;
      }
      if (this.matchedOCR) {
        this.$root.$emit("openEcReviewModal", { ocr: this.matchedOCR, file });
      }
    },
    showECReview(file) {
      const ocrDocs = this.$store.state?.document?.ocrDocs;
      for (const doc of ocrDocs) {
        if (file.policyDocVerId === doc.policyDocVerId) return true;
      }
      return false;
    },
    async onSubmitDocuments() {
      this.loading = true;
      const numDocs = this.createNewDocument.docs.length;
      const errors = {};
      let i = 0;
      while (i < this.createNewDocument.docs.length) {
        const doc = this.createNewDocument.docs[i];
        try {
          // const filteredDocType = this.docTypesArray.filter((x) => x.type === this.docType);
          const url = Constants.UPLOAD_URL.replace("{{policyNo}}", this.policyNo).replace(
            "{{docType}}",
            doc.docTypeId
          );
          const newFile = new File([doc.file], doc.file.name.replace(/ /g, "_"));
          const fd = new window.FormData();
          fd.append(newFile.name, newFile, newFile.name);

          await authAxios.post(url, fd);
          this.createNewDocument.docs.splice(i, 1);
        } catch (e) {
          console.error("error", e);
          errors[doc.name] = e;
          i++;
        }
      }

      if (numDocs !== this.createNewDocument.docs.length) this.init();
      if (Object.keys(errors).length) {
        this.$msgbox({
          message:
            `<ul class="list-disc">` +
            Object.keys(errors)
              .map(
                (name) =>
                  `<li>Error uploading '${name}': ${
                    errors[name].response.data.message.includes(
                      "The specified blob already exists."
                    )
                      ? "This document has already been uploaded to the policy."
                      : errors[name].message
                  }</li>`
              )
              .join("") +
            `</ul>`,
          dangerouslyUseHTMLString: true,
          title: "Error",
          iconClass: "hidden"
        });
      }
      if (this.createNewDocument.docs.length === 0) this.createNewDocument.open = false;
      this.loading = false;
    },
    previewDoc(i) {
      const fileURL = URL.createObjectURL(this.createNewDocument.docs[i].file);
      window.open(fileURL, "_blank");
    },
    dropFiles(e) {
      this.$refs.file.files = e.dataTransfer.files;
      this.fileInputChange();
      this.createNewDocument.isDragging = false;
    },
    fileInputChange() {
      for (const file of this.$refs.file.files)
        this.createNewDocument.docs.push({
          file,
          name: file.name
        });
    },
    deleteDoc(i) {
      this.createNewDocument.docs.splice(i, 1);
    },
    ...mapActions("document", ["deleteDocumentAxios"]),
    showToast(string, type, title) {
      this.$notify({
        title: title,
        message: string,
        type: type
      });
    },
    closeUploadDialog() {
      this.showModal = false;
    },
    async docSuccess() {
      await this.init();
      this.loading = false;
    },
    async docError() {
      this.loading = false;
    },
    showAddDocumentModal() {
      this.showModal = !this.showModal;
    },
    isPartyRequired(folderName, party) {
      const document = this.signedDocs?.find((d) => d?.document?.docName === folderName);
      return document?.partiesToSign?.includes(party);
    },
    isDocumentSigned(folderName, party) {
      const document = this.unsignedDocs.find((d) => d.document.docName === folderName);
      if (!document) return true;
      const partySignatures = document.signatures.filter((s) => s.party === party);
      if (partySignatures.length === 0) return false;
      return partySignatures.every((s) => Boolean(s.signedAt));
    },
    async toggleManualSign(e, folderName, party) {
      e.stopPropagation();
      const url = Constants.PUT_FLAG_TOGGLE;
      const isSigned = this.isDocumentSigned(folderName, party);

      const sendBody = {
        PolicyNo: this.$store.state?.policy?.confirmationData?.policyNo,
        PolicyDocId:
          isSigned === false
            ? this.unsigned[folderName][0]?.document?.policyDocId
            : this.allDocs[folderName][0]?.policyDocId,
        Parties: [party],
        setFlag: !isSigned
      };
      await authAxios
        .put(url, sendBody)
        .then(() =>
          this.$store.dispatch(
            "policy/getPolicyDetails",
            this.$store.state?.policy?.confirmationData?.policyNo
          )
        );
    },
    isFolderToggled(folderName) {
      return this.folderToggleState[folderName];
    },
    showVersions(e, folderName) {
      e.stopPropagation();
      this.toggleFolderState(folderName);
    },
    toggleFolderState(folderName) {
      const selected = this.folderToggleState[folderName];
      this.folderToggleState = { ...this.folderToggleState, [folderName]: !selected };
    },
    async init() {
      const policyNo = this.$store.state.policy.confirmationData.policyNo;
      const docs = await this.$store.dispatch("document/getAllPolicyDocuments2", policyNo);

      await this.$store.dispatch("document/load", policyNo, {
        root: true
      });
      const groupedDocs = docs.reduce((group, item) => {
        let friendlyName = item.docName.trim();
        if (item.versions.some((v) => v.isUpload)) {
          friendlyName = "Uploads";
        }
        if (item.versions.some((v) => v.docName === "nyEndorsement")) {
          friendlyName = "NY Endorsement";
        }

        const arr = group[friendlyName] ?? [];
        let reversed = item.versions.slice(0).reverse();
        if (friendlyName === "Endorsement" || friendlyName === "NY Endorsement") {
          // only add the most recent version for endorsements
          const [mostRecentVersion] = reversed;
          arr.push(mostRecentVersion);
        } else {
          // add every version for files that are not endorsements and set reversed = reversed.reverse();
          reversed = item.versions;
          arr.push(...reversed);
        }
        arr.reverse();
        group[friendlyName] = arr;
        return group;
      }, {});
      delete groupedDocs.Invoice;
      if (groupedDocs.Uploads)
        groupedDocs.Uploads.sort((a, b) => a.docName.localeCompare(b.docName));
      // Object.assign(this.allDocs, groupedDocs);
      this.allDocs = groupedDocs;
      Object.keys(groupedDocs).forEach(this.toggleFolderState, this);

      this.groupUnsignedDocs();
    },
    groupUnsignedDocs() {
      const unsignedGrouped = this.unsignedDocs?.reduce((group, item) => {
        const friendlyName = item.document.docName;
        const arr = group[friendlyName] ?? [];
        arr.push(item);
        group[friendlyName] = arr;
        return group;
      }, []);
      this.unsigned = unsignedGrouped;
    },
    // Document Download
    /** @param {neptune.IPolicyPdfDocument} item */
    downloadRecentVersion(folder) {
      if (!this.canOpen(folder[folder.length - 1])) return;
      const path = Constants.GET_PDF_URL + "?link=" + folder[folder.length - 1].link;
      window.open(path, "_blank");
    },
    /** @param {neptune.IPolicyPdfDocument} item */
    openDocument(item) {
      if (!this.canOpen(item)) return;
      const path = Constants.GET_PDF_URL + "?link=" + item.link;
      window.open(path, "_blank");
    },
    /** @param {neptune.IPolicyPdfDocument} item */
    canOpen(item) {
      const { link } = item;

      return !!link;
    },
    handleSDFileChange(file, fileList) {
      console.log(fileList, "this is the file list");
      if (fileList.length > 10) {
        this.maxFileAlert = true;
      }
      this.SDFileList = fileList.slice(-10); // no more than 10 docs
      // count the number of files successfully uploaded:
      const cntUploaded = fileList.filter(
        (p) => p.response != null && p.response.statusCode === "200"
      ).length;
      if (this.SDFileList.length > 0 && cntUploaded !== this.SDFileList.length) {
        this.uploadSDComplete = false;
      }
      // This clears the list after all files are uploaded:
      if (this.SDFileList.length > 0 && cntUploaded === this.SDFileList.length) {
        this.clearSDUploads();
      }
    },
    GetUploadDocsURL(docType) {
      const policyNo = this.$store.state.policy.confirmationData.policyNo;
      const url = Constants.UPLOAD_URL.replace("{{policyNo}}", policyNo).replace(
        "{{docType}}",
        docType
      );
      return url;
    },
    UploadSupportingDocs() {
      const docType = 6;
      return this.GetUploadDocsURL(docType);
    },
    beforeSDUpload: function(file) {
      const newFile = new File([file], file.name.replace(/ /g, "_"));
      const url = this.UploadSupportingDocs();
      // TODO: Two file names: 1)Filename (name of asset) and 2)File name (description).
      //   replace below fileDescription with user-entered File name.
      this.beforeUpload(newFile, url, newFile.name);
      return false; // Return false does not upload automatically
    },
    beforeUpload: function(file, url, fileDescription) {
      const fd = new window.FormData();
      fd.append(fileDescription, file, file.name);

      authAxios
        .post(url, fd)
        .then((res) => {
          console.log("Status Code: " + res.data.statusCode);
          this.init();
        })
        .catch((error) => {
          console.log("Error in upload");
          console.dir(error);
        });
    },
    submitSDUpload() {
      const check = this.SDFileList.map((x) => x.name);
      for (let i = 0; i < check.length; i++) {
        if (this.docNameCheck.includes(check[i])) {
          this.duplicateDocFlag = true;
          return;
        }
      }

      this.$refs.SupportingDocsUpload.submit();
      this.docNameCheck = [];
      this.maxFileAlert = false;
    },
    SDFileUploadSucceeded() {
      console.log("------- succeeded");
      this.uploadSDComplete = true;
      this.reload();
    },
    beforeECUpload: function(file) {
      const url = this.UploadECDocs();
      this.beforeUpload(file, url, "Elevation Certificate");
      return false; // Return false does not upload automatically
    },
    UploadECDocs() {
      const docType = 4;
      return this.GetUploadDocsURL(docType);
    },
    handleECFileChange(file, fileList) {
      this.ECFileList = fileList.slice(-10); // no more than 10 docs
      // count the number of files successfully uploaded:
      const cntUploaded = fileList.filter(
        (p) => p.response != null && p.response.statusCode === "200"
      ).length;
      if (this.ECFileList.length > 0 && cntUploaded !== this.ECFileList.length) {
        this.uploadECComplete = false;
      }
      // This clears the list after all files are uploaded:
      if (this.ECFileList.length > 0 && cntUploaded === this.ECFileList.length) {
        this.clearECUploads();
      }
    },
    submitECUpload() {
      this.$refs.ECUpload.submit();
    },
    ECFileUploadSucceeded() {
      console.log("------- succeeded");
      this.uploadECComplete = true;
      this.reload();
    },
    onUploadError: function(err) {
      console.log("Error uploading file ");
      console.dir(err);
    },
    mapNamesToCheck() {
      this.$store.state.document.documents.map((x) => this.docNameCheck.push(x.docName));
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.deleteLoading = false;
    },
    deleteDocumentStart(file) {
      this.deleteLoading = true;
      this.showDeleteModal = true;

      this.selectedToDelete = {};
      this.selectedToDelete = file;
    },
    async deleteDocumentSubmit() {
      try {
        this.loading = true;
        const policyNo = this.$store.state.policy.confirmationData.policyNo;
        const { policyDocId, policyDocVerId } = this.selectedToDelete;
        await this.deleteDocumentAxios({
          policyNo: policyNo,
          policyDocId: policyDocId,
          policyDocVerId: policyDocVerId
        });
        await this.init();
        this.showToast("Document successfully deleted!", "success", "Success");
      } catch {
        this.showToast("There was an error deleting the document.", "error", "Error");
      } finally {
        this.showDeleteModal = false;
        this.selectedToDelete = {};
        this.deleteLoading = false;
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
table.uploaded {
  width: 100%;
  th {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }

  tr + tr {
    background-color: darken(#fff, 1);

    border-top: 1px solid darken(#fff, 15);

    &:nth-child(even) {
      background-color: darken(#fff, 3);
    }
  }

  td,
  th {
    padding: 5px 8px;
  }
  .col-style {
    width: 180px;
  }
  .col-style-right {
    width: 180px;
    text-align: right;
  }
  .col-container {
    width: 500px;
  }
  .indent {
    padding-left: 23px;
  }
}

.col-style-right {
  width: 180px;
  text-align: right;
}
.col-style-name {
  width: 200px;
  text-align: left;
}
.col-container {
  width: 500px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.hideDropdown {
  display: none;
}
.sign-button {
  margin-left: 10px;
  width: 70px;
}
.timestamp {
  font-size: 0.85em;
  color: rgb(146, 146, 146);
}
.center-icon {
  padding-left: 5%;
}
.file-upload-list-container {
  display: flex;
  width: 100%;
  padding: 5px 0px;
}
.snippetClosed {
  width: 300px !important;
}
.ec-snippet-open {
  width: 350px;
  color: red;
  font-family: "Lato";
}

.delete-margins {
  margin-left: -14px;
  margin-right: 7px;
  cursor: pointer;
  &:hover {
    color: crimson;
    filter: brightness(110%);
  }
}
::v-deep .dialog-body {
  height: 250px;
}
::v-deep .upload-dialog-body {
  height: 430px;
}
</style>
