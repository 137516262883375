<template>
  <div
    :key="policyNo"
    v-loading="submitting"
    class="flex flex-col flex-1 policy-detail p-4"
    :class="{
      'is-agent': agent,
      cancelled: cancelled && !showTimeline,
      'is-loading': loading,
      'saturate-[.25]':
        ((isEarthquake && cancelled) ||
          (isEarthquake && policyData.isExpired) ||
          (isEarthquake &&
            $store.state.policy.confirmationData &&
            $store.state.policy.confirmationData.isNonRenewal)) &&
        showModal === false &&
        showResend === false &&
        !showTimeline,
      'saturate-50':
        ((!isEarthquake && cancelled) ||
          (!isEarthquake &&
            $store.state.policy.confirmationData &&
            !$store.state.policy.confirmationData.isNonRenewal &&
            !active) ||
          (!isEarthquake && policyData.isExpired)) &&
        showModal === false &&
        showResend === false &&
        !showTimeline
    }"
  >
    <div class="flex flex-col flex-1 relative">
      <!-- Parallax -->
      <div
        class="fixed h-[400px] top-0 right-0 left-0 transition duration-200 ease-in-out overflow-hidden can-gray"
      >
        <div
          ref="mover"
          class="absolute inset-0"
          :class="{
            'bg-gradient-to-r from-[#f9e7c6] via-[#ffdaed] to-[#ffdaed] rotate-30': isEarthquake
          }"
        ></div>
      </div>

      <!-- Content -->
      <div key="content" class="content-container">
        <!-- Title -->
        <!-- <div class="content-container__title mt-4 mb-6" data-test="policyDetailTitle">
          <div data-test="policyDetailLogo">
            <img
              v-if="isEarthquake"
              data-test="earthquakeLogo"
              alt="Earthquake Logo"
              src="@/assets/jumpstart.svg"
              class="h-[50px] w-auto"
            />

            <img
              v-else
              alt="Residential Logo"
              data-test="residentialLogo"
              src="@/assets/pngs/Neptune_Horizon_White.png"
              class="h-[100px] w-auto"
            />

            <div
              v-if="[6, 7, 8].includes($store.getters['policy/productNo'])"
              class="text-center mt-1"
            >
              <span
                class="ml-[-7px] text-sm bg-pink-100 text-pink-900 inline-flex rounded-full mt-1.5 px-2 py-0.5 mx-auto  border border-pink-200 font-bold"
              >
                Excess Flood
              </span>
            </div>
          </div>
        </div> -->

        <!-- Policy Content -->
        <div class="policies relative gpu can-gray">
          <div v-if="policyItems && !loading">
            <div class="lg:flex lg:space-x-8">
              <!-- Left Column -->
              <div class="lg:w-[350px] flex-shrink-0 space-y-4">
                <!-- mobile action buttons -->
                <div v-if="agent && !$readonly" class="mb-4 flex flex-wrap justify-start sm:hidden">
                  <div
                    v-if="
                      ($internal && !policyCancelled && !pendingCancel) ||
                        (!$internal && !policyCancelled && !pendingCancel && !policyData.isExpired)
                    "
                    class="flex"
                  >
                    <span class="mr-2">
                      <button
                        key="Endorse"
                        data-test="actionButtonMobile-Endorse"
                        class="text-white font-bold py-1.5 px-3 text-sm rounded-md  inline-flex items-center space-x-1"
                        :class="{
                          'bg-jetblack-full hover:bg-jetblack-full/75': !isEarthquake,
                          'bg-brand-orange hover:bg-brand-orange/75': $isEarthquake
                        }"
                        @click.prevent="endorse"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z"
                          />
                        </svg>

                        <span>
                          Endorse
                        </span>
                      </button>
                    </span>
                  </div>

                  <button
                    v-if="!hasUserSigned || !paid"
                    :type="isEarthquake ? 'primary' : 'success'"
                    data-test="policyDetailMobile.paymentButton"
                    class="text-white font-bold py-1.5 px-3 text-sm rounded-md inline-flex items-center space-x-1"
                    :class="{
                      'bg-deepsea-medium hover:bg-deepsea-medium/75': !isEarthquake,
                      'bg-brand-orange hover:bg-brand-orange/75': $isEarthquake
                    }"
                    @click.prevent="hasUserSigned ? showSignAndPayModal(2) : showSignAndPayModal(1)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 4a1 1 0 011-1h16a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1V4zm12 4a3 3 0 11-6 0 3 3 0 016 0zM4 9a1 1 0 100-2 1 1 0 000 2zm13-1a1 1 0 11-2 0 1 1 0 012 0zM1.75 14.5a.75.75 0 000 1.5c4.417 0 8.693.603 12.749 1.73 1.111.309 2.251-.512 2.251-1.696v-.784a.75.75 0 00-1.5 0v.784a.272.272 0 01-.35.25A49.043 49.043 0 001.75 14.5z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span v-if="hasUserSigned" data-test="paymentBtn-Pay">Pay</span>
                    <span v-else-if="onlySigsRequired && !paid" data-test="paymentBtn-SignAndPay"
                      >Sign & Pay
                    </span>
                    <span v-else-if="onlySigsRequired" data-test="paymentBtn-Sign">Sign</span>
                  </button>
                </div>
                <!-- / mobile action buttons -->

                <PolicyDetailAlert
                  v-if="showPolicyDetailAlert"
                  class="sm:hidden"
                  @pay="
                    wizardStep = 2;
                    showModal = true;
                  "
                  @sign="
                    wizardStep = 1;
                    showModal = true;
                  "
                />
                <SuccessBanner v-else-if="!$readonly" class="sm:hidden" />

                <!-- Map -->
                <map-display
                  :key="fullAddress"
                  class="-mt-16 border-4 border-deepsea-full shadow-md mb-1"
                  :address="fullAddress"
                  data-test="mapDisplay"
                  :disabled="$readonly"
                />

                <div class="bg-white shadow sm:rounded-lg p-6 text-center">
                  <div v-if="[6, 7, 8].includes($store.getters['policy/productNo'])" class="mb-2">
                    <span
                      class="text-base border border-pink-200 font-bold bg-pink-100 text-pink-900 inline-flex rounded-full px-2 py-0.5"
                    >
                      Excess Flood
                    </span>
                  </div>

                  <div>
                    <label class="text-gray-500 font-bold text-xl" data-test="policyNoLabel"
                      >Policy Number</label
                    >
                  </div>
                  <div class="text-3xl select-text" data-test="policyNoField">
                    {{ policyData.policyNo.toUpperCase() }}
                  </div>
                  <div v-if="!$insured" class="w-full mt-4 space-y-1">
                    <button
                      type="primary"
                      size="small"
                      :disabled="$readonly"
                      data-test="resendEmailLinkButton"
                      class="!text-sm  border px-3 py-2 rounded font-bold hover:text-white transition duration-200 ease-in-out w-full hover:text-white inline-flex space-x-1 text-center justify-center items-center"
                      :class="{
                        'border-brand-orange text-brand-orange hover:bg-brand-orange hover:border-brand-orange': isEarthquake,
                        'border-deepsea-full text-deepsea-full hover:bg-deepsea-full hover:border-deepsea-full': !isEarthquake
                      }"
                      @click="$root.$emit('openResendEmailLinkModal')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z"
                        />
                      </svg>

                      <span>Resend Email Link</span>
                    </button>

                    <button
                      class="!text-sm  border px-3 py-2 rounded font-bold hover:text-white transition duration-200 ease-in-out w-full hover:text-white inline-flex space-x-1 text-center justify-center items-center"
                      :class="{
                        'border-brand-orange text-brand-orange hover:bg-brand-orange hover:border-brand-orange': isEarthquake,
                        'border-deepsea-full text-deepsea-full hover:bg-deepsea-full hover:border-deepsea-full': !isEarthquake
                      }"
                      type="primary"
                      size="small"
                      data-test="viewNotificationsButton"
                      @click="routeToNotifications()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 2a6 6 0 00-6 6c0 1.887-.454 3.665-1.257 5.234a.75.75 0 00.515 1.076 32.91 32.91 0 003.256.508 3.5 3.5 0 006.972 0 32.903 32.903 0 003.256-.508.75.75 0 00.515-1.076A11.448 11.448 0 0116 8a6 6 0 00-6-6zM8.05 14.943a33.54 33.54 0 003.9 0 2 2 0 01-3.9 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span> View Notifications</span>
                    </button>
                    <button
                      class="!text-sm  border px-3 py-2 rounded font-bold hover:text-white transition duration-200 ease-in-out w-full hover:text-white inline-flex space-x-1 text-center justify-center items-center"
                      :class="{
                        'border-brand-orange text-brand-orange hover:bg-brand-orange hover:border-brand-orange': isEarthquake,
                        'border-deepsea-full text-deepsea-full hover:bg-deepsea-full hover:border-deepsea-full': !isEarthquake
                      }"
                      data-test="viewTimelineButton"
                      @click="showTimeline = true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <span>View Timeline</span>
                    </button>
                  </div>
                </div>
                <div>
                  <button
                    v-if="showViewAllPolicies"
                    :disabled="$readonly"
                    class="rounded-md  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm  w-full"
                    :class="{
                      'bg-deepsea-full hover:bg-deepsea-full/75': !isEarthquake,
                      'bg-brand-orange hover:bg-brand-orange/75': isEarthquake
                    }"
                    data-test="viewAllPoliciesButton"
                    @click="routeToSelect"
                  >
                    View All Policies
                  </button>
                </div>

                <!-- Current Status -->
                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    title="Current Status"
                    icon="el-icon-odometer"
                    data-test="currentStatusLabel"
                  >
                    <div>
                      <StatusDisplay
                        :datecheck="policyData.inceptionDate"
                        @setSubmitting="submitting = true"
                        @setUnsubmitting="submitting = false"
                        @fetchPendingPremium="init"
                        @refreshOnNonRenew="init"
                      />
                      <template>
                        <div>
                          <div class="status-labels">
                            <h3 v-if="$store.getters['policy/isPaid']" data-test="paidLabel">
                              <div class="flex flex-col my-2">
                                <div class="text-sm flex items-center space-x-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5 text-green-600"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    data-test="paidFlag"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <span>
                                    <span v-if="$store.getters['policy/allData'].currentlyDue <= 0"
                                      >No
                                    </span>
                                    <span
                                      >Balance Due
                                      <span v-if="$store.getters['policy/allData'].currentlyDue > 0"
                                        >:</span
                                      ></span
                                    >
                                  </span>
                                </div>
                                <div v-if="lastPaymentType" class="text-xs pl-[24px]">
                                  Method of Payment:
                                  <strong data-test="methodOfPayment">{{ lastPaymentType }}</strong>
                                </div>
                              </div>
                            </h3>

                            <h3
                              v-else
                              data-test="unpaidLabel"
                              class="text-sm flex items-center space-x-1 my-2"
                            >
                              <!-- exclamation -->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5 text-red-700"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                data-test="unpaidFlag"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <span
                                class="underline cursor-pointer"
                                :disabled="$readonly"
                                @click="showSignAndPayModal(2)"
                                >Unpaid</span
                              >
                            </h3>

                            <div
                              v-if="
                                !hideCancellationSignatures &&
                                  !$store.getters['policy/cancelReason/requireESignature'] &&
                                  !policyCancelled
                              "
                              :class="{ 'my-6': policyCancelled || pendingCancel }"
                            >
                              <div v-if="policyCancelled || pendingCancel" class="label">
                                <span
                                  data-test="cancellationSignaturesFlag"
                                  class="font-bold !text-sm !text-gray-500"
                                  >Cancellation Signatures</span
                                >
                              </div>

                              <h3
                                v-if="$store.getters['policy/hasInsuredSigned']"
                                class="text-sm flex items-center space-x-1 my-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5 text-green-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  data-test="insuredSignedFlag"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <span>Insured Signed</span>
                              </h3>
                              <h3
                                v-else-if="!$store.getters['policy/isInsuredSignatureRequired']"
                                class="text-sm flex items-center space-x-1 my-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                  class="h-5 w-5 text-yellow-400"
                                  data-test="insuredNotRequiredFlag"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                <span>Insured Not Required</span>
                              </h3>
                              <h3 v-else class="text-sm flex items-center space-x-1 my-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  data-test="notSignedByInsuredFlag"
                                  class="h-5 w-5 text-red-700 text-sm"
                                >
                                  <path
                                    data-v-72b645f0=""
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                <span data-test="notSignedByInsured">Not Signed By Insured</span>
                              </h3>

                              <h3
                                v-if="$store.getters['policy/hasAgentSigned']"
                                class="text-sm flex items-center space-x-1 my-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5 text-green-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  data-test="agentSignedFlag"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <span>Agent Signed</span>
                              </h3>
                              <h3
                                v-else-if="!$store.getters['policy/isAgentSignatureRequired']"
                                class="text-sm flex items-center space-x-1 my-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                  class="h-5 w-5 text-yellow-400"
                                  data-test="agentNotRequiredFlag"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>

                                <span>Agent Not Required</span>
                              </h3>
                              <h3 v-else class="text-sm flex items-center space-x-1 my-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  data-test="notSignedByAgentFlag"
                                  class="h-5 w-5 text-red-700"
                                >
                                  <path
                                    data-v-72b645f0=""
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                <span
                                  class="underline cursor-pointer"
                                  @click="showSignAndPayModal(1)"
                                  >Not Signed By Agent</span
                                >
                              </h3>

                              <div class="disable-in-cancel mt-1">
                                <span v-if="!signed.insured && false">
                                  <el-button
                                    size="mini"
                                    type="primary"
                                    icon="el-icon-message"
                                    data-test="emailInsuredButton"
                                    @click="generateEmail"
                                    >Email Insured To Sign
                                  </el-button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <ul class="flex flex-col flex-wrap mt-4">
                            <li class="flex w-full items-center justify-between space-x-2">
                              <div
                                class="text-base text-gray-900 font-bold flex-1 w-full"
                                data-test="totalPremiumTitle"
                              >
                                Total Premium
                                <p class="text-sm !font-normal ">Including Taxes & Fees</p>
                              </div>

                              <div
                                v-if="premiumTotal"
                                class="text-base font-black ml-auto select-text"
                                data-test="totalPremiumAmount"
                              >
                                {{ premiumTotal | currency }}
                              </div>
                              <div
                                v-else
                                class="text-base font-black ml-auto select-text"
                                data-test="totalPremiumAmount"
                              >
                                $0.00
                              </div>
                            </li>
                            <li v-if="isCancelled" class="flex w-full mt-3">
                              <div
                                class="text-gray-900 font-bold flex-1 w-full"
                                data-test="earnedPremiumTitle"
                              >
                                Neptune Earned Premium
                              </div>

                              <div
                                v-if="premiumTotal + pendingTotalPremium !== 0"
                                class="text-base font-black ml-auto select-text"
                                data-test="earnedPremiumAmount"
                              >
                                {{ (premiumTotal + pendingTotalPremium) | currency }}
                              </div>
                              <div
                                v-else
                                class="text-base font-black ml-auto select-text"
                                data-test="earnedPremiumAmount"
                              >
                                $0.00
                              </div>
                            </li>
                            <li class="flex w-full mt-3">
                              <div
                                class="text-base text-gray-900 font-bold flex-1 w-full"
                                data-test="amountPaidTitle"
                              >
                                Amount Paid:
                              </div>

                              <div
                                v-if="totalPayments !== 0"
                                class="text-base font-black ml-auto select-text"
                                data-test="amountPaidAmount"
                              >
                                {{ totalPayments | currency }}
                              </div>

                              <div
                                v-else
                                class="text-base font-black ml-auto select-text"
                                data-test="amountPaidAmount"
                              >
                                $0.00
                              </div>
                            </li>
                            <li
                              v-if="isCancelled && totalPayments !== 0"
                              class="flex w-full diff-item mt-3"
                            >
                              <div
                                class="text-blue-600 font-bold flex-1 w-full"
                                data-test="insuredRefundTitle"
                              >
                                Refund To Insured:
                              </div>
                              <div
                                v-if="refundToInsured < 0"
                                class="text-base font-black ml-auto select-text"
                                data-test="insuredRefundAmount"
                              >
                                {{ (refundToInsured * -1) | currency }}
                              </div>
                              <div
                                v-else
                                class="text-base font-black ml-auto select-text"
                                data-test="insuredRefundAmount"
                              >
                                $0.00
                              </div>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </div>
                  </collapser-section>
                </div>

                <div class="bg-white shadow sm:rounded-lg">
                  <diff-collapser-section
                    title="Insured Location"
                    :current-index="currentIndex"
                    :objects="locationDiffs"
                    icon="el-icon-location"
                    data-test="insuredLocationLabel"
                  >
                    <div class="text-base -mb-2" data-test="insuredLocationField">
                      <div class="text-base select-text" data-test="insuredAddr1Field">
                        {{ activePolicyItem.addr1 }}
                      </div>
                      <span
                        v-if="activePolicyItem.addr2"
                        class="text-base select-text"
                        data-test="insuredAddr2Field"
                        >{{ activePolicyItem.addr2 }}</span
                      >

                      <div data-test="insuredCityStateField" class="text-base select-text">
                        {{ activePolicyItem.city }}, {{ activePolicyItem.state }}
                        {{ activePolicyItem.zip }}
                      </div>
                      <div data-test="insuredCountyField" class="text-base select-text">
                        {{ activePolicyItem.county }} {{ countyDisplay }}
                      </div>
                    </div>
                  </diff-collapser-section>
                </div>

                <div class="bg-white shadow sm:rounded-lg">
                  <diff-collapser-section
                    title="Insured Contact Information"
                    :current-index="currentIndex"
                    :objects="contactInfoDiffs"
                    icon="el-icon-s-custom"
                    data-test="contactInfoLabel"
                  >
                    <template #default="{ keyedObject }">
                      <contact-info-content :keyed-object="keyedObject" />
                    </template>
                  </diff-collapser-section>
                </div>

                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    v-if="$store.state.policy.lienholderInfo.length"
                    icon="el-icon-office-building"
                    title="Lienholder Info"
                    data-test="additionalInterestLabel"
                  >
                    <lienholder-info
                      :can-modify="!policyCancelled && !pendingCancel"
                      @update="updateLH"
                    />
                  </collapser-section>
                </div>

                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    title="Agency Contact Information"
                    icon="el-icon-user"
                    data-test="agencyInfoLabel"
                  >
                    <agency-details
                      :agent-id="activeAgentId"
                      :can-change-agency="canChangeAgency"
                      @modify-agency="modifyAgency()"
                    />
                  </collapser-section>
                </div>
              </div>

              <!-- Right Content -->
              <div class="flex-grow space-y-4 mt-4">
                <!-- Action Buttons -->
                <div
                  v-if="agent && !$readonly"
                  class="action-buttons mb-4 !hidden sm:!flex flex-wrap justify-start"
                >
                  <div
                    v-if="
                      ($internal && !policyCancelled && !pendingCancel) ||
                        (!$internal && !policyCancelled && !pendingCancel && !policyData.isExpired)
                    "
                    class="flex"
                  >
                    <span class="mr-2">
                      <button
                        key="Endorse"
                        data-test="policyDetail.endorseButton"
                        class="text-white font-bold py-1.5 px-3 text-sm rounded-md  inline-flex items-center space-x-1"
                        :class="{
                          'bg-deepsea-full hover:bg-deepsea-full/75': !isEarthquake,
                          'bg-brand-orange hover:bg-brand-orange/75': $isEarthquake
                        }"
                        @click.prevent="endorse"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z"
                          />
                        </svg>

                        <span>
                          Endorse
                        </span>
                      </button>
                    </span>
                  </div>
                  <button
                    v-if="
                      $store.getters['auth/agentNo'] === 'FL0008' ||
                        $store.getters['auth/agentNo'] === 'FL50251' ||
                        $store.getters['auth/agentNo'] === 'FL50254' ||
                        $store.getters['auth/agentNo'] === 'FL84589'
                    "
                    class="text-white font-bold py-1.5 px-3 text-sm rounded-md mr-2 inline-flex items-center"
                    :class="{
                      'bg-brand-blue hover:bg-brand-blue/75': !isEarthquake,
                      'bg-brand-orange hover:bg-brand-orange/75': $isEarthquake,
                      'cursor-not-allowed': internalEndorsementLoading
                    }"
                    :disabled="internalEndorsementLoading"
                    @click.prevent="fetchInternalEndorsement"
                  >
                    <span
                      v-if="!internalEndorsementLoading"
                      class="inline-flex items-center space-x-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z"
                        />
                      </svg>

                      <span data-test="policyDetail.internalEndorseButton">
                        Internal Endorse
                      </span>
                    </span>
                    <span v-else class="inline-flex items-center space-x-2">
                      <svg
                        class="animate-spin h-5 w-5 text-white mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span data-test="policyDetail.internalEndorseLoading">Loading...</span>
                    </span>
                  </button>
                  <button
                    v-if="!hasUserSigned || !paid"
                    :type="isEarthquake ? 'primary' : 'success'"
                    data-test="paymentButton"
                    class="text-white font-bold py-1.5 px-3 text-sm rounded-md inline-flex items-center space-x-1"
                    :class="{
                      'bg-deepsea-full hover:bg-deepsea-full/75': !isEarthquake,
                      'bg-brand-orange hover:bg-brand-orange/75': $isEarthquake
                    }"
                    @click.prevent="hasUserSigned ? showSignAndPayModal(2) : showSignAndPayModal(1)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 4a1 1 0 011-1h16a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1V4zm12 4a3 3 0 11-6 0 3 3 0 016 0zM4 9a1 1 0 100-2 1 1 0 000 2zm13-1a1 1 0 11-2 0 1 1 0 012 0zM1.75 14.5a.75.75 0 000 1.5c4.417 0 8.693.603 12.749 1.73 1.111.309 2.251-.512 2.251-1.696v-.784a.75.75 0 00-1.5 0v.784a.272.272 0 01-.35.25A49.043 49.043 0 001.75 14.5z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span v-if="hasUserSigned" data-test="paymentBtn-Pay">Pay</span>
                    <span v-else-if="onlySigsRequired && !paid" data-test="paymentBtn-SignAndPay"
                      >Sign & Pay
                    </span>
                    <span v-else-if="onlySigsRequired" data-test="paymentBtn-Sign">Sign</span>
                  </button>
                </div>
                <!-- / action buttons -->

                <div
                  v-if="needsECUpload"
                  class="rounded-md bg-red-50 border-l-4 border-red-400  p-4"
                >
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: solid/x-circle -->
                      <svg
                        class="h-5 w-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="text-sm font-medium text-red-800" data-test="uploadEC">
                        This policy requires an Elevation Certificate. Please upload an EC below.
                      </h3>
                    </div>
                  </div>
                </div>

                <div
                  v-if="needsPolicyRolloverUpload"
                  class="rounded-md bg-red-50 border-l-4 border-red-400  p-4"
                >
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: solid/x-circle -->
                      <svg
                        class="h-5 w-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <h3 class="text-sm font-medium text-red-800 ml-3">
                      Waiting period waived - Please upload a HUD-1 Closing Document, or Policy
                      Rollover Declarations Page below
                    </h3>
                  </div>
                </div>

                <div
                  v-if="
                    $internal && $store.state.policy.confirmationData.isNonRenewal && !isEarthquake
                  "
                  class="rounded-md bg-red-50 p-4 border-l-4 border-red-400"
                >
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: solid/x-circle -->
                      <svg
                        class="h-5 w-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="text-base font-medium text-red-800 m-0" data-test="policyNonRenew">
                        This policy is set to non-renew
                      </h3>
                    </div>
                  </div>
                </div>

                <PolicyDetailAlert
                  v-if="showPolicyDetailAlert"
                  class="!hidden sm:!block"
                  @pay="
                    wizardStep = 2;
                    showModal = true;
                  "
                  @sign="
                    wizardStep = 1;
                    showModal = true;
                  "
                />
                <SuccessBanner v-else-if="!$readonly" class="!hidden sm:!block" />

                <div
                  v-if="
                    $store.getters['policy/isInsuredSignatureRequired'] &&
                      !$store.getters['policy/hasInsuredSigned'] &&
                      !$store.getters['policy/allData'].isPendingCancel
                  "
                  class="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded"
                >
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-yellow-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <p class="text-yellow-700 text-base leading-none font-bold mb-0.5">
                        <span
                          v-if="!$store.getters['policy/hasInsuredSigned']"
                          data-test="notSignedByInsured"
                          >Not Signed By Insured</span
                        >
                        <span v-else data-test="waitingForAllParties"
                          >Waiting for all parties to finish...</span
                        >
                      </p>

                      <p class="text-sm text-yellow-700" data-test="needsActivationBefore">
                        Needs activation before
                        {{ $store.getters["policy/allData"].effectiveDate | shortDate }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Tasks -->
                <collapser-section
                  v-if="false"
                  title="Active Tasks"
                  icon="el-icon-s-release"
                  class="tasks"
                >
                  <p class="text-xs mb-4">
                    This contains a list of tasks needed to complete based on the current state of
                    this policy.
                  </p>

                  <!-- <policy-tasks /> -->
                </collapser-section>

                <!-- Overview -->
                <div class="flex flex-col md:flex-row bg-white shadow sm:rounded-lg">
                  <collapser-section
                    title="Overview"
                    icon="el-icon-finished"
                    :class="hideUserGoals ? 'overviewFull' : 'overviewPartial'"
                  >
                    <!-- The slot content gives back additional info -->
                    <template #default>
                      <OverviewSection
                        :policy-no="policyNo"
                        :usergoals="hideUserGoals"
                        @successfulInceptionChange="refreshPolicy()"
                      />
                    </template>
                  </collapser-section>
                </div>
                <!-- policy history -->
                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section title="Policy History" icon="el-icon-document">
                    <PolicyHistoryTable />
                  </collapser-section>
                </div>

                <!-- Invoice Details -->
                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    title="Invoices"
                    icon="el-icon-money"
                    data-test="invoicesLabel"
                    :collapse-section="isInsured"
                  >
                    <invoice-details @signandpay="showSignAndPayModal()" />
                  </collapser-section>
                </div>

                <!-- Payments Details -->
                <div v-if="showPaymentMethodGrid" class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    title="Payment Methods"
                    icon="el-icon-money"
                    data-test="paymentMethodLabel"
                    :collapse-section="isInsured"
                  >
                    <PaymentMethodGrid
                      :policy-no="policyNo"
                      :row-data="payments"
                      :default-payment-method="defaultPaymentMethod"
                      @refreshPaymentMethod="refreshPaymentMethod"
                    />
                  </collapser-section>
                </div>

                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    title="Payments"
                    icon="el-icon-money"
                    data-test="paymentsLabel"
                    :collapse-section="isInsured"
                  >
                    <PaymentsGrid
                      :policy-no="policyNo"
                      :row-data="payments"
                      :default-payment-method="defaultPaymentMethod"
                      @refreshPaymentMethod="refreshPaymentMethod"
                    />
                  </collapser-section>
                </div>

                <!-- Documents -->
                <div id="policyDocuments" class="bg-white shadow sm:rounded-lg overflow-hidden">
                  <collapser-section
                    title="Policy Documents"
                    icon="el-icon-document"
                    data-test="documentsLabel"
                  >
                    <DocumentsView />
                  </collapser-section>
                </div>

                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    v-if="($agent || $internal) && !isEarthquake"
                    title="Claims"
                    icon="el-icon-notebook-2"
                    data-test="claimsLabel"
                  >
                    <ClaimsGrid :row-data="claimsArr" :policy-no="policyNo" @update="getClaims()" />
                  </collapser-section>
                </div>

                <!-- Coverages -->
                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    title="Coverages"
                    icon="el-icon-umbrella"
                    data-test="coveragesLabel"
                  >
                    <!-- Table view -->
                    <div class="table-coverages">
                      <coverage-info />
                    </div>
                  </collapser-section>
                </div>

                <!-- Diff Viewer -->
                <div v-if="false" class="bg-white shadow sm:rounded-lg">
                  <diff-collapser-section
                    v-if="$debug || $route.query.dev"
                    title="Last Changes"
                    icon="el-icon-set-up"
                    class="overflow-x-auto"
                    :objects="policyItems"
                    :current-index="currentIndex"
                  >
                    <template #list="obj">
                      <div class="gpu">
                        <div
                          v-if="obj.diff"
                          class="
                          last-changes
                          flex flex-col
                          xl:flex-row xl:items-center
                          line-height-1
                          mb-5
                          xl:mb-0
                          flex-wrap
                        "
                        >
                          <span class="mr-2">{{ obj.key }}:</span>
                          <pre class="text-blue-600">{{ obj.values.old }}</pre>
                          <span class="fa fa-fw text-xs el-icon-arrow-right mx-2" />
                          <pre class="text-green-600 font-black">{{ obj.values.new }}</pre>
                        </div>
                      </div>
                    </template>

                    <template #default>
                      <span v-if="policyItems.length < 2">There are no changes yet</span>
                    </template>
                  </diff-collapser-section>
                </div>

                <!-- Transactions -->
                <div class="bg-white shadow sm:rounded-lg">
                  <collapser-section
                    v-if="transactions"
                    title="Transactions"
                    :collapse-section="isInsured"
                    icon="el-icon-chat-line-square"
                    data-test="transactionsLabel"
                  >
                    <div>
                      <transaction-grid
                        :row-data="computedTransactions"
                        @showSelectedTransaction="setSelectedTransaction($event)"
                      />
                    </div>
                  </collapser-section>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- spinner overlay -->
        <div v-show="loading" key="preloader" class="content-loading">
          <preloader-spinner path="/static/lottie/preloader3.json" :loop="true" />
        </div>

        <!-- Debug Util -->
        <div
          v-if="false"
          class="fixed top-0 right-0 mt-2 mr-2 w-48 rounded bg-gray-100 text-black !shadow-sm space-y-1 p-3"
        >
          <el-switch v-model="loading" active-text="Loading" />
          <el-button size="mini" class="!w-full !mx-0" @click="toggleUserType($event, true)">
            Insured Welcome
          </el-button>
          <el-button size="mini" class="!w-full !mx-0" @click="toggleUserType($event, false)">
            Insured Cancel
          </el-button>
        </div>
      </div>

      <!-- Policy Wizard Dialog -->

      <div
        class="fixed inset-0 z-[2000]"
        :class="{ 'pointer-events-none': !showTimeline && !showModal && !showTransactionModal }"
      >
        <div
          class="fixed inset-0 overflow-hidden"
          aria-labelledby="slide-over-title"
          role="dialog"
          aria-modal="true"
        >
          <!-- Background backdrop, show/hide based on slide-over state. -->
          <transition
            mode="out-in"
            enter-class="opacity-0"
            enter-active-class="transition ease-in-out duration-500 sm:duration-700"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-active-class="transition ease-in-out duration-500 sm:duration-700"
            leave-to-class="opacity-0"
          >
            <div
              v-if="showModal"
              class="fixed inset-0 bg-black bg-opacity-75"
              @click="showModal = false"
            />
          </transition>

          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full lg:pl-10">
            <transition
              mode="out-in"
              enter-class="translate-x-full"
              enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
              enter-to-class="translate-x-0"
              leave-class="translate-x-0"
              leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
              leave-to-class="translate-x-full"
            >
              <div v-if="showModal" class="pointer-events-auto w-full md:w-screen max-w-6xl">
                <div class="flex h-full flex-col ">
                  <div class="relative flex-1 h-full">
                    <!-- Replace with your content -->
                    <PolicyWizard
                      v-bind="wizardOptions"
                      @payComplete="onPayComplete"
                      @signComplete="signComplete"
                      @clickToClose="closeModal"
                    >
                      <template #upload>
                        <cancel-wizard-dialog :step.sync="cancelStep" @cancel="showModal = false" />
                      </template>
                      <template #refund>
                        <cancel-wizard-dialog :step.sync="cancelStep" @cancel="showModal = false" />
                      </template>
                    </PolicyWizard>
                    <!-- /End replace -->
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <!-- timeline -->
        <div class="inset-0 z-[2000]" :class="{ 'pointer-events-none': !showTimeline }">
          <div
            class="fixed inset-0 overflow-hidden"
            aria-labelledby="slide-over-title"
            role="dialog"
            aria-modal="true"
          >
            <!-- Background backdrop, show/hide based on slide-over state. -->
            <transition
              mode="out-in"
              enter-class="opacity-0"
              enter-active-class="transition ease-in-out duration-500 sm:duration-700"
              enter-to-class="opacity-100"
              leave-class="opacity-100"
              leave-active-class="transition ease-in-out duration-500 sm:duration-700"
              leave-to-class="opacity-0"
            >
              <div
                v-if="showTimeline"
                class="fixed inset-0 bg-black bg-opacity-75"
                @click="showTimeline = false"
              />
            </transition>

            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w- lg:pl-10">
              <transition
                mode="out-in"
                enter-class="translate-x-full"
                enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                enter-to-class="translate-x-0"
                leave-class="translate-x-0"
                leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                leave-to-class="translate-x-full"
              >
                <div v-if="showTimeline" class="pointer-events-auto w-full md:w-screen max-w-2xl">
                  <div class="flex h-full flex-col ">
                    <div class="relative flex-1 h-full">
                      <!-- Replace with your content -->
                      <TimeLine
                        :payments="payments"
                        :is-commercial="isCommercial"
                        :is-earthquake="isEarthquake"
                        :policy-no="policyNo"
                        :notes="notes"
                        @closeTimeline="showTimeline = false"
                      />
                      <!-- /End replace -->
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import words from "lodash/words";
import startCase from "lodash/startCase";
import { mapActions, mapState } from "vuex";
import { diff } from "deep-object-diff";
import GoogleMapDisplayVue from "@/components/routes/policy-details/GoogleMapDisplay.vue";
import SuccessBanner from "@/components/SuccessBanner.vue";
import CollapserSectionVue from "./policies/CollapserSection.vue";
import DiffCollapserSectionVue from "./policies/DiffCollapserSection.vue";
import { formatPhone, formatDate } from "@/utils/TextFormatting";
import PolicyContactInfoSectionVue from "./policies/PolicyContactInfoSection.vue";
import { NumberFiltersMixin, DateFiltersMixin } from "../policy-wizard/utils/mixins/formatting";
import LienholderInfoVue from "../organisms/policy-detail/LienholderInfo.vue";
import CoverageInfoVue from "../organisms/policy-detail/CoverageInfo.vue";
import StatusDisplay from "../organisms/policy-detail/StatusDisplay.vue";
import LottieViewerVue from "@/components/policy-wizard/components/LottieViewer.vue";
import InvoiceDetailsVue from "../organisms/policy-detail/InvoiceDetails.vue";
import { authAxios } from "../../store/modules/auth";
import { Constants } from "../../config";
import PolicyWizard from "../policy-wizard/index.vue";
import OverviewSectionVue from "./policy-details/OverviewSection.vue";
import { clearLocalStorage } from "../../utils/data";
import PolicyDetailAlert from "@/components/PolicyDetailAlert.vue";
import CancelWizardDialogVue from "../organisms/policy-detail/CancelWizardDialogNew.vue";
import { copyToClipboard } from "../../utils";
import TransactionGridVue from "@/components/organisms/grids/TransactionGrid.vue";
import AgencyDetailsVue from "./policy-details/AgencyDetails.vue";
import DocumentsViewVue from "./policy-details/DocumentsView";
import PolicyHistoryTable from "@/components/PolicyHistoryTable";
import ClaimsGrid from "../organisms/grids/ClaimsGrid.vue";
import PaymentsGrid from "../organisms/grids/PaymentsGrid.vue";
import PaymentMethodGrid from "../organisms/grids/PaymentMethodGrid.vue";
import TimeLine from "../timeline/Timeline.vue";
import Swal from "sweetalert2";

export default {
  components: {
    SuccessBanner,
    PolicyDetailAlert,
    TimeLine,
    MapDisplay: GoogleMapDisplayVue,
    PaymentsGrid,
    PaymentMethodGrid,
    CollapserSection: CollapserSectionVue,
    DiffCollapserSection: DiffCollapserSectionVue,
    ContactInfoContent: PolicyContactInfoSectionVue,
    LienholderInfo: LienholderInfoVue,
    CoverageInfo: CoverageInfoVue,
    StatusDisplay,
    PreloaderSpinner: LottieViewerVue,
    InvoiceDetails: InvoiceDetailsVue,
    PolicyWizard,
    OverviewSection: OverviewSectionVue,
    CancelWizardDialog: CancelWizardDialogVue,
    TransactionGrid: TransactionGridVue,
    AgencyDetails: AgencyDetailsVue,
    DocumentsView: DocumentsViewVue,
    PolicyHistoryTable,
    ClaimsGrid
  },
  filters: {
    /** @type {(date: number | string) => string} */
    dateFormat: (value) => {
      if (value.endsWith("Z")) {
        const noTimezoneValue = value.substring(0, value.length - 1);
        return formatDate(noTimezoneValue);
      }
      return formatDate(value);
    },

    formatPhone: formatPhone,

    /**
     * @type {(str: string) => string}
     */
    words(str) {
      return startCase(words(str).join(" "));
    }
  },
  mixins: [NumberFiltersMixin, DateFiltersMixin],
  props: {
    policyNo: String
  },
  data() {
    return {
      internalEndorsementLoading: false,
      claimsArr: [],
      showTimeline: false,
      selectedTransaction: null,
      showTransactionModal: false,
      resendLoading: false,
      pendingTotalPremium: null,
      transferAmountMax: 0,
      amountParam: 0,
      hideUserGoals: false,
      path: this.$route.fullPath,
      paymentIdArray: [],
      toInfo: null,
      noPaymentSelectedAlert: false,
      transferAmount: 0,
      toPolicyNumber: "",
      selectedTransferPayment: null,
      showTransferModal: false,
      notes: [],
      newEmail: "",
      showResend: false,
      scroll: {
        x: 0,
        y: 0
      },
      wizardStep: 0,
      cancelStep: 1,
      loading: true,
      submitting: false,
      currentIndex: 0,
      // documents:    [],
      lazySearch: null,
      showModal: false,

      isModifyingAgencyNo: false,
      modifiedAgencyNo: "",
      modifiedAgencyReason: "",
      modifyAgencyNoError: null,
      defaultPaymentMethod: null
    };
  },
  computed: {
    needsPolicyRolloverUpload() {
      // Get the inception date from your store
      const inceptionDateString = this.$store.getters["policy/allData"].inceptionDate;

      // Create Date objects for inception date and today
      const inceptionDate = new Date(inceptionDateString);
      const today = new Date();

      // Calculate the difference in milliseconds
      const differenceInTime = today.getTime() - inceptionDate.getTime();

      // Convert the difference to days
      const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

      // Check if the difference is less than 60 days
      const isLessThan60Days = differenceInDays < 60;

      // EC Doc is 21
      return (
        this.documents.filter((p) => p.docTypeId === 3 || p.docTypeId === 21).length === 0 &&
        this.$store?.getters["policy/allData"]?.hasImmediateClosing &&
        isLessThan60Days
      );
    },
    hideCancellationSignatures() {
      return ["P05", "P06"].includes(this.$store.state.policy.transactionSummary.transactionType);
    },
    computedTransactions() {
      const summary = this.$store?.state?.policy?.transactionSummary?.transactions;
      return this.transactions?.map((t) => {
        return {
          ...t,
          userName: summary.find((st) => st.policyTransactionId === t.policyTransactionId)?.userName
        };
      });
    },
    showPolicyDetailAlert() {
      if (this.$readonly) return false;

      const isAgentSignatureRequired = this.$store.getters["policy/isAgentSignatureRequired"];
      const hasAgentSigned = this.$store.getters["policy/hasAgentSigned"];
      const isPaid = this.$store.getters["policy/isPaid"];
      const isInsuredSignatureRequired = this.$store.getters["policy/isInsuredSignatureRequired"];
      const hasInsuredSigned = this.$store.getters["policy/hasInsuredSigned"];

      const showIfAgentAndNotSigned =
        !this.$insured &&
        ((isAgentSignatureRequired && !hasAgentSigned) || (hasAgentSigned && !isPaid));

      const showIfInsuredAndNotSigned =
        this.$insured &&
        ((isInsuredSignatureRequired && !hasInsuredSigned) || (hasInsuredSigned && !isPaid));

      const showIfSignaturesNotRequiredAndUnpaid =
        !isInsuredSignatureRequired && !isAgentSignatureRequired && !isPaid;

      return (
        showIfAgentAndNotSigned || showIfInsuredAndNotSigned || showIfSignaturesNotRequiredAndUnpaid
      );
    },
    active() {
      return this.policyData.canCancelPolicy === true || this.policyData.grossPremium !== 0;
    },
    isInsured() {
      return this.$store.getters["auth/accountType"] === "policyholder";
    },

    isResidential() {
      return [1, 6].includes(this.$store.getters["policy/productNo"]);
    },
    isCommercial() {
      return [2, 3, 7, 8].includes(this.$store.getters["policy/productNo"]);
    },
    isEarthquake() {
      return (
        this.$store.getters["policy/productNo"] === 4 ||
        this.$store.getters["policy/productNo"] === 5
      );
    },
    isExcess() {
      return [6, 7, 8].includes(this.$store.getters["policy/productNo"]);
    },

    showViewAllPolicies() {
      return this.$store.getters["auth/accountType"] === "policyholder";
    },
    Invoices() {
      const {
        policy: { confirmationData }
      } = this.$store.state;
      return confirmationData?.invoices;
    },
    premiumTotal() {
      return this.$store.getters["policy/allData"]?.invoices?.total;
    },
    refundToInsured() {
      return (
        this.Invoices.due -
        Math.abs(this.$store?.state?.policy?.confirmationData?.pendingCancelTotalPremium)
      );
    },
    isCancelled() {
      return (
        this.$store.getters["policy/allData"].isPendingCancel ||
        this.$store.getters["policy/allData"].isPolicyCancelled
      );
    },
    totalPayments() {
      return this.$store.getters["policy/allData"].totalPayments;
    },
    showRefund() {
      return this.$store.getters["policy/allData"].totalPayments !== 0;
    },
    countyDisplay() {
      return this.activePolicyItem?.state === "LA" ? "Parish" : "County";
    },
    party() {
      return this.$store.getters["userdata/party"];
    },
    onlySigsRequired() {
      const docs = this.$store.getters["document/unsignedDocs"];
      return docs.filter((x) => x.party === this.party);
    },
    canModifyAgency() {
      if (this.isModifyingAgencyNo) return false;
      if (!this.modifiedAgencyNo?.trim()) return false;
      if (!this.modifiedAgencyReason?.trim()) return false;
      return true;
    },
    signaturesRequired() {
      const agent = this.$store.getters["policy/isAgentSignatureRequired"];
      const insured = this.$store.getters["policy/isInsuredSignatureRequired"];
      return {
        agent,
        insured
      };
    },
    payments() {
      const newPayments = this.policyData.payments.reduce((rows, payment) => {
        const {
          paymentId,
          createdAt,
          policyPaymentId,
          description,
          payment: {
            paymentStatus,
            checkNo,
            memo,
            paymentTypeId,
            paymentType: { paymentTypeDescription }
          },
          amount
        } = payment;

        const newRows = [
          {
            policyPaymentId,
            paymentId,
            paymentTypeDescription,
            paymentStatus,
            paymentTypeId,
            createdAt,
            memo,
            description,
            isAppliedToPolicy: true,
            checkNo,
            amount
          }
        ];
        return [...rows, ...newRows];
      }, []);

      return newPayments;
    },
    showPaymentMethodGrid() {
      return (
        this.defaultPaymentMethod?.stripePaymentMethodId &&
        this.defaultPaymentMethod?.default &&
        this.defaultPaymentMethod?.optInAutoPayRenewal
      );
    },
    toInfoComputed() {
      return {
        addr1: this.toInfo?.addr1,
        city: this.toInfo?.city,
        state: this.toInfo?.state,
        zip: this.toInfo?.zip
      };
    },

    computedTransferCheck() {
      return this.selectedTransferPayment?.amount < this.transferAmount;
    },
    hasInsuredSigned() {
      return this.$store.getters["policy/hasInsuredSigned"] || !this.signaturesRequired.insured;
    },
    hasAgentSigned() {
      return this.$store.getters["policy/hasAgentSigned"] || !this.signaturesRequired.agent;
    },
    action: {
      get() {
        return this.$route.params.action;
      },
      set(val) {
        const params = { ...this.$route.params };
        // delete params.action;
        params.action = val;
        this.$router.push({ name: this.$route.name, params });
      }
    },
    documents: {
      get() {
        return this.$store.state.document.pdfs;
      },
      set(val) {
        this.$store.commit("document/SET_PROP", {
          path: "pdfs",
          value: val
        });
      }
    },
    policyCancelledCheck() {
      if (this.policyCancelled) {
        return this.policyCancelled;
      }
    },
    /** @type {() => neptune.IPolicyData} */
    policyData() {
      return this.$store.getters["policy/allData"];
    },
    invoiceData() {
      return this.policyData.invoices;
    },
    paidLastByUserType() {
      // find the latest invoice, if any:
      const lastestInvoice = this.lastPaidInvoice;
      // get the user type of the person that paid last:
      const paidBy = lastestInvoice?.paidLastByUserType;
      return paidBy;
    },
    lastPaidInvoice() {
      if (!this.invoiceData.invoices.length) return null;
      const lastestInvoice = this.invoiceData.invoices.reduce((pre, cur) => {
        return Date.parse(pre.updatedAt) > Date.parse(cur.updatedAt) ? pre : cur;
      });
      return lastestInvoice;
    },
    lastPayment() {
      const [lastItem] = this.policyData.payments.slice().splice(-1);
      return lastItem;
    },
    lastPaymentType() {
      const lastPaymentType = this.lastPayment?.payment?.paymentType?.paymentTypeDescription;
      return lastPaymentType;
    },
    wizardOptions() {
      return {
        policyNo: this.$route.params.policyNo,
        party: this.userType,
        authToken: this.$store.state.auth.token
      };
    },

    hasUserSigned() {
      return (
        this.$store.getters["policy/hasUserSigned"] ||
        !this.$store.getters["policy/isUserSignatureRequired"]
      );
    },

    /** @type {() => {agent: boolean, insured: boolean, currentUser: boolean}} */
    signed: {
      get() {
        const isSigned = this.policyData.isSigned || [];

        const agent = isSigned.some((i) => i.isSigned && i.party === 1);
        const insured = isSigned.some((i) => i.isSigned && i.party === 2);

        return {
          agent,
          insured,
          currentUser: this.agent ? agent : insured
        };
      },

      set(bool) {
        /** @type {neptune.policyholder.IPolicySummaryResponse} */
        const { isSigned } = this.$store.state.policy.confirmationData;

        /** @type {neptune.policyholder.IsSigned[]} */
        const cloned = JSON.parse(JSON.stringify(isSigned));
        const matched = cloned.find((v) => v.party === this.userType);
        matched.isSigned = bool;

        this.$store.commit("policy/SET_PROP", {
          path: "confirmationData.isSigned",
          value: cloned
        });
      }
    },

    paid: {
      get() {
        return this.policyData.isPaid;
      },

      set(bool) {
        this.$store.commit("policy/SET_PROP", {
          path: "confirmationData.isPaid",
          value: bool
        });
      }
    },

    /**
     * @type {() => neptune.policyholder.IPolicyTransactionItem[]}
     * @returns {neptune.policyholder.IPolicyTransactionItem[]}
     */
    policyItems() {
      if (this.transactions && this.transactions.length) {
        return this.transactions;
      }
    },

    /**
     * @type {() => neptune.policyholder.IPolicyTransactionItem}
     */
    activePolicyItem() {
      if (this.transactions && this.transactions.length) {
        return this.transactions[this.currentIndex];
      }

      return null;
    },

    activeAgentId() {
      return this.activePolicyItem?.agencyId;
    },

    /**
     * @returns {string}
     */
    fullAddress() {
      if (this.activePolicyItem) {
        const { addr1, addr2, city, state, zip } = this.activePolicyItem;
        return `${addr1 + " " + addr2} ${city}, ${state} ${zip}`;
      }
    },

    /**
     * @type {Vue.ComputedOptions<string> | (() => any)}
     */
    locationDiffs() {
      return this.policyItems.map((v) => {
        const { addr1, addr2, city, state, zip } = v;

        return {
          addr1,
          addr2,
          city,
          state,
          zip
        };
      });
    },

    contactInfoDiffs() {
      return this.policyItems.map((v) => {
        const {
          firstName,
          lastName,
          coApplicantFirstName,
          coApplicantLastName,
          coApplicantPhone,
          coApplicantEmail,
          email,
          phone,
          mailingAddr1,
          mailingAddr2,
          mailingCountry,
          mailingCity,
          mailingState,
          mailingZip,
          companyName
        } = v;

        return {
          firstName,
          lastName,
          coApplicantFirstName,
          coApplicantLastName,
          coApplicantPhone,
          coApplicantEmail,
          companyName,
          email,
          phone,
          mailingAddr1,
          mailingAddr2,
          mailingCountry,
          mailingCity,
          mailingState,
          mailingZip
        };
      });
    },

    ...mapState("quoting", ["transactions"]),

    /**
     * @type {() => any}
     */
    coverages() {
      if (this.activePolicyItem) {
        /**
         * @type {neptune.policyholder.IPolicyTransactionItem}
         *
         */
        const {
          coverageALimit,
          coverageAPremium,
          coverageBLimit,
          coverageBPremium,
          coverageILimit,
          coverageIPremium
        } = this.activePolicyItem;

        return [
          {
            letter: "A",
            title: "Building Property",
            color: "#004080",
            limit: coverageALimit,
            premium: coverageAPremium
          },
          {
            letter: "B",
            title: "Business Personal Property",
            color: "#dd31cb",
            limit: coverageBLimit,
            premium: coverageBPremium
          },
          {
            letter: "D",
            title: "Increased Cost of Compliance",
            color: "#128000",
            limit: 30000,
            premium: "Included"
          },
          {
            letter: "I",
            title: "Business Interruption",
            color: "#209686",
            limit: coverageILimit === 1 ? "up to $25,000" : coverageILimit,
            premium: coverageIPremium
          },
          {
            letter: "C",
            title: "Other Coverages - Loss Avoidance",
            color: "rgb(221, 89, 49)",
            limit: 1000,
            premium: "Included"
          },
          {
            letter: "C",
            title: "Other Coverages - Pollution",
            color: "rgb(148, 3, 3)",
            limit: 10000,
            premium: "Included"
          }
        ];
      }
    },

    /**
     * @type {() => object}
     */
    diffed() {
      if (this.policyItems && this.policyItems.length > 1) {
        const [prev, cur] = this.policyItems.slice(this.policyItems.length - 2);

        const d = diff(prev, cur);

        return d;
      }
    },

    statusDiffs() {
      return this.policyItems.map((v) => {
        const { inceptionDate, effectiveDate } = v;

        return {
          inceptionDate,
          effectiveDate
        };
      });
    },

    /**
     * Total taxes and costs of the policy
     */
    totals() {
      const { policyFee, grossPremium, totalPremium } = this.policyData;

      return {
        policyFee,
        grossPremium,
        totalPremium
      };
    },

    userType() {
      return this.$route.meta?.userType || 1;
    },

    agent() {
      return this.userType === 1;
    },
    policyCancelled() {
      const { policy } = this.$store.state;
      return policy?.confirmationData?.isPolicyCancelled;
    },
    cancelled() {
      return this.policyCancelled && !this.pendingCancel;
    },
    cancelReason() {
      return this.$store.getters["policy/cancelReason"];
    },
    pendingCancel() {
      return this.policyData.isPendingCancel;
    },

    /** @type {() => neptune.IAggregatedAmounts} */
    amounts() {
      return this.$store.getters["policy/aggregatedTransactionValues"];
    },

    canChangeAgency() {
      return this.$internal && !this.cancelled && !this.pendingCancel;
    },

    taxItems() {
      const {
        transactionFeePolicyPremium,
        surplusTaxPolicyPremium,
        surplusTaxPolicyFee,
        stampingFeesPolicyPremium,
        stampingFeesPolicyFee,
        policyFee,
        mwuaPolicyPremium,
        mwuaPolicyFee,
        municipalTax,
        firemanTax,
        maintenanceAssessment,
        deductible,
        deductibleAdjustment
      } = this.amounts;

      return {
        transactionFeePolicyPremium,
        surplusTaxPolicyPremium,
        surplusTaxPolicyFee,
        stampingFeesPolicyPremium,
        stampingFeesPolicyFee,
        policyFee,
        mwuaPolicyPremium,
        mwuaPolicyFee,
        municipalTax,
        firemanTax,
        maintenanceAssessment,
        deductible,
        deductibleAdjustment
      };
    },

    needsECUpload() {
      // EC Doc is 4
      return this.ecRequired && !this.ecUploaded;
    },
    ecRequired() {
      return this.policyData.ecElevation > 0;
    },
    ecUploaded() {
      return this.documents.filter((p) => p.docTypeId === 4).length > 0;
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        await this.init();

        // this opens resendEmailLinkModal if query string is set to true
        // required for ToDo list feature on agent hub
        const url = new URL(window.location.href);
        const hashParams = new URLSearchParams(url.hash.split("?")[1]);

        if (hashParams.get("resendEmailLinkModalOpen") === "true") {
          this.$root.$emit("openResendEmailLinkModal");
        } else if (hashParams.get("signatureAgentModalOpen") === "true") {
          this.showSignAndPayModal(1);
        } else if (hashParams.get("paymentModalOpen") === "true") {
          this.showSignAndPayModal(2);
        } else if (hashParams.get("uploadElevationCertificateModal") === "true") {
          this.scrollToPolicyDocuments();
          this.$root.$emit("openUploadDocumentModal", "Elevation Certificate");
        } else if (hashParams.get("uploadHud1Modal") === "true") {
          this.scrollToPolicyDocuments();
          this.$root.$emit("openUploadDocumentModal", "HUD-1");
        } else if (hashParams.get("uploadDeclarationsModal") === "true") {
          this.scrollToPolicyDocuments();
          this.$root.$emit("openUploadDocumentModal", "Declarations Page");
        }
        // end query string / todo list functionality
      }
    }
  },
  /** Reset transactions before page is mounted */
  beforeMount() {
    this.$store.commit("quoting/SET_PROP", {
      path: "transactions",
      value: null
    });
  },
  async mounted() {
    this.$root.$on("refreshOnReinstate", () => {
      this.init();
    });

    this.$root.$on("refreshPolicyData", () => {
      this.init();
    });

    // set relevant meta for ada bot
    this.setAdaMeta();

    this.$root.$on("closeModal", () => {
      this.showModal = false;
    });

    this.$root.$on("completeSignature", () => {
      setTimeout(() => {
        this.refreshPolicy();
      }, 100);
    });

    this.$root.$on("completePayment", () => {
      setTimeout(() => {
        this.refreshPolicy();
      }, 100);
    });
  },
  methods: {
    async fetchInternalEndorsement() {
      try {
        this.internalEndorsementLoading = true;
        const response = await authAxios.get(Constants.ENDORSEMENT_EBO_URL, {
          params: {
            policyNo: this.policyNo,
            effectiveDate: this.$store.getters["policy/allData"].inceptionDate
          }
        });

        window.open(response.data.payload.link + "?endorsementType=EboEbm", "_blank");
      } catch (error) {
        console.log(error);
      } finally {
        this.internalEndorsementLoading = false;
      }
    },
    scrollToPolicyDocuments() {
      const element = document.getElementById("policyDocuments");

      if (element) {
        element.scrollIntoView({
          behavior: "smooth", // animate the scroll
          block: "center" // align to the top of the viewport
        });
      } else {
        console.warn('Element with ID "policyDocuments" not found.');
      }
    },
    async getClaims() {
      try {
        const claims = await this.getAllClaims(this.policyNo);
        this.claimsArr = claims;
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "There was an error fetching the claims data.",
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
        this.claimsArr = [];
        console.error("Error fetching claims", error);
      }
    },

    setAdaMeta() {
      let meta = {};
      // update meta with agent / policyholder info in case
      // user arrived from embed link
      const { accountType } = this.$store.state.auth;
      if (accountType !== "policyholder") {
        const { AgentId, Company, Email, FirstName, LastName, Phone } = this.$store.state.auth.data;
        meta = {
          ...meta,
          agent_name: FirstName && LastName ? `${FirstName} ${LastName}` : Company,
          agent_number: AgentId,
          agent_email: Email,
          agent_phone: Phone,
          is_policy_holder: false
        };
      }

      this.$ada.setSensitiveMetaFields(meta);
      this.$ada.setMetaFields({
        policy_number: this.$route.params.policyNo
      });
    },
    setSelectedTransaction(data) {
      if (data.transactionType !== "EB") return;
      const result = this.$store?.state?.policy?.transactionSummary?.transactions.find(
        (x) => x.policyTransactionId === data.policyTransactionId
      );

      this.selectedTransaction = result;
      this.showTransactionModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    hideModal() {
      this.hideUserGoals = true;
    },
    reloadWindow() {
      window.location.reload();
    },
    modifyAgency() {
      this.modifiedAgencyNo = "";
      this.modifiedAgencyReason = "";
      this.$root.$emit("openChangeAgencyModal");
      this.showEditAgencyDialog = true;
    },
    async refreshPolicy() {
      await this.getInfo(this.policyNo).catch((error) => {
        this.$router.replace({
          name: "Error",
          path: "/error",
          params: {
            error: error,
            policyNo: this.policyNo
          }
        });
      });
    },
    async updateAgency() {
      try {
        this.modifyAgencyNoError = null;
        this.isModifyingAgencyNo = true;
        const url = Constants.ENDORSE_POLICY_URL.replace("{{policyNo}}", this.policyNo);
        const { data } = await authAxios.post(url, {
          endorsementType: "aor",
          agentNo: this.modifiedAgencyNo.trim(),
          notes: this.modifiedAgencyReason.trim()
        });
        if (data.statusCode !== 200) {
          throw new Error(data.message);
        }
        this.showEditAgencyDialog = false;
        await this.init();
      } catch (e) {
        const message =
          e.response?.data?.message ?? "Unable to update agency. Please try again later";
        this.modifyAgencyNoError = message;
      } finally {
        this.isModifyingAgencyNo = false;
      }
    },
    async refreshNotes() {
      const res = await this.getPolicyNotes(this.policyNo);
      this.notes = res;
    },
    async init() {
      this.loading = true;
      await this.getClaims();
      const [notes] = await Promise.all([
        this.getPolicyNotes(this.policyNo).catch(() => {
          console.warn("Unable to fetch notes for this policy");
          return [];
        }),

        this.getInfo(this.policyNo).catch((error) => {
          this.$router.replace({
            name: "Error",
            path: "/error",
            params: {
              error: error,
              policyNo: this.policyNo
            }
          });
        }),
        this.getActiveTransactions([this.policyNo, false]).catch((error) => {
          this.$router.replace({
            name: "Error",
            path: "/error",
            params: {
              error: error,
              policyNo: this.policyNo
            }
          });
        })
      ]);

      this.defaultPaymentMethod = await this.getDefaultPaymentMethod(this.policyNo).catch(
        (error) => {
          this.$router.replace({
            name: "Error",
            path: "/error",
            params: {
              error: error,
              policyNo: this.policyNo
            }
          });
        }
      );

      let meta = {};

      const {
        email,
        firstName,
        lastName,
        phone,
        agencyNo
      } = this.$store.state.policy.confirmationData;
      meta = {
        ...meta,
        policyholder_email: email,
        policyholder_name: firstName && lastName ? `${firstName} ${lastName}` : firstName,
        policyholder_phone: phone,
        agent_number: agencyNo
      };

      const policyNo = this.$route.params.policyNo;
      this.$ada.setSensitiveMetaFields(meta);
      this.$ada.setMetaFields({
        policy_number: policyNo
      });

      const transactionsArray = await this.getPolicyTransactions([this.policyNo, false]);

      this.pendingTotalPremium =
        transactionsArray[transactionsArray.length - 1].pendingTotalPremium;

      this.notes = notes;

      this.currentIndex = (this.policyItems || []).length - 1;

      onscroll = this.updateScroll;

      this.$once("hook:beforeDestroy", () => {
        onscroll = null;
      });

      this.loading = false;
    },
    async refreshPaymentMethod() {
      this.defaultPaymentMethod = await this.getDefaultPaymentMethod(this.policyNo);
    },
    updateLH(message) {
      if (message === "failed") {
        this.getInfo(this.policyNo).then(() => {
          this.$notify.error({
            title: "Error",
            message: "There was an error updating this Lienholder."
          });
        });
      } else if (message === "endorsementError") {
        this.getInfo(this.policyNo).then(() => {
          this.$notify.error({
            title: "Error",
            message: "There was an error creating an endorsement to edit the Lienholder."
          });
        });
      } else {
        this.getInfo(this.policyNo).then(() => {
          this.$notify({
            title: "Success",
            message: "Lienholder updated successfully!",
            type: "success"
          });
        });
      }
    },
    routeToNotifications() {
      this.$router.push({
        path: `${this.$route.path}/notifications`,
        params: {
          policyNo: this.policyNo
        }
      });
    },

    duplicateTransferModal() {
      this.$notify({
        title: "Alert",
        message: "You've already transfered this payment.",
        type: "warning"
      });
    },
    checkArray(arr, item) {
      return arr.filter((x) => item === x).length;
    },

    onReceiptClick(payment) {
      this.$router.push({
        path: `${this.$route.path}/receipt/${payment.policyPaymentId}`,
        params: {
          createdAt: payment.createdAt
        }
      });
    },
    async onGridClick(obj) {
      const { invoices } = this.policyData;
      this.selectedTransferPayment = obj;

      if (!this.selectedTransferPayment) {
        this.noPaymentSelectedAlert = true;
        return;
      }
      if (
        this.selectedTransferPayment.paymentStatus === "failed" ||
        this.selectedTransferPayment.paymentStatus === "cancelled" ||
        (invoices.due === 0 && invoices.paid === 0) ||
        this.selectedTransferPayment.isAppliedToPolicy === false
      ) {
        this.showTransferModal = false;
        return;
      }
      if (this.selectedTransferPayment.memo === "Reversing payment") {
        this.duplicateTransferModal();
        return;
      }
      if (this.policyData.totalDue > 0) {
        const paymentArr = this.policyData.payments.filter((x) => x.paymentId === obj.paymentId);
        let total = 0;
        paymentArr.map((x) => (total = x.amount + total));
        this.transferAmountMax = total;
      } else {
        this.transferAmountMax = obj.amount;
      }
      this.showTransferModal = true;
    },

    showResendModal() {
      this.showResend = true;
    },
    async sendNewLink() {
      try {
        this.resendLoading = true;
        const emailToSend = this.newEmail === "" ? this.policyData.email : this.newEmail;
        await this.sendNewLinkAxios({ policyNo: this.policyNo, email: emailToSend });
        this.showResend = false;
        this.$notify({
          title: "Success",
          message: "Email has successfully sent!",
          type: "success"
        });
      } catch (e) {
        this.$notify.error({
          title: "Error",
          message: "There was an error sending the email"
        });
        throw new Error(e);
      } finally {
        this.resendLoading = false;
      }
    },

    /**
     * @type {(ev: MouseEvent, welcome: boolean) => void}
     */
    async toggleUserType(ev, welcome = true) {
      const agentRoute = this.$router.resolve({
        name: "policyDetail",
        params: {
          policyNo: this.policyNo
        }
      }).normalizedTo;

      const insuredRoute = this.$router.resolve({
        name: "policyHolderDetail",
        params: {
          policyNo: this.policyNo
        }
      }).normalizedTo;

      // debugger

      if (this.agent) {
        const t = await this.$store.dispatch("auth/getInsuredToken");
        clearLocalStorage();

        const url =
          "http://localhost:8081/#/policyholder/" + (welcome ? "new" : "cancel") + "/" + t;

        // window.open(url, ev.ctrlKey ? '_blank' : '_self')
        copyToClipboard(url);
        this.$notify({
          message: "copied url to clipboard"
        });
        // this.$notify
      } else {
        this.$router.push(!this.agent ? agentRoute : insuredRoute);
      }
    },
    /**
     * Parralax Scrolling
     * @type {() => void}
     */
    updateScroll() {
      const {
        // scrollX: x,
        pageYOffset: y
      } = window;

      this.scroll.y = y;
      this.$refs.mover.style.transform = `translate3d(0,-${y / 2}px,0)`;
    },

    /** @type {(msg: string) => void} */
    log(msg) {
      return console.log(msg);
    },

    /**
     * @type {() => Promise<string>}
     * call the createEndorsement API and get a URL back to redirect user:
     */
    async endorse() {
      this.submitting = true;
      const {
        data: {
          result: { endorsementQuoteNumber: endorseQuoteId }
        }
      } = await authAxios.post(Constants.CREATE_ENDORSE_URL, `"${this.policyNo}"`, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      const tritonUrl = `${Constants.BASE_TRITON_UI_URL}/#/quote/commercial/${endorseQuoteId}/${this.policyNo}`;

      this.submitting = false;
      window.open(tritonUrl, "_blank");

      return endorseQuoteId;
    },

    ...mapActions("claims", ["getAllClaims"]),
    ...mapActions("quoting", ["getActiveTransactions"]),
    ...mapActions("payment", ["transferPayment"]),
    ...mapActions("policy", {
      deletePolicyNote: "deletePolicyNote",
      getPolicyNotes: "getPolicyNotes",
      updatePolicyNote: "updatePolicyNote",
      upsertPolicyNote: "upsertPolicyNote",
      sendNewLinkAxios: "sendNewLinkAxios",
      getInfo: "getPolicyDetails",
      getPolicyTransactions: "getActiveTransactions",
      getDefaultPaymentMethod: "getDefaultPaymentMethod"
    }),

    showSignAndPayModal(step) {
      if (this.$readonly) return;
      this.wizardStep = step || (!this.signed.currentUser ? 1 : 2);
      this.showModal = true;
    },

    signComplete() {
      this.signed = true;
      if (!this.paid) {
        if (this.pendingCancel) {
          // this.wizardStep = 'pendingCancel'
          this.showModal = false;
        } else {
          this.wizardStep++;
        }
      } else {
        this.showModal = false;
      }
    },

    onPayComplete() {
      this.paid = true;
      this.showModal = false;
    },
    async generateEmail() {
      try {
        const email = this.policyData.email;
        const phone = this.policyData.phone;
        const zip = this.policyData.zip;
        await this.$store.dispatch("auth/sendEmail", { email: email, phone: phone, zip: zip });
        return this.$msgbox({
          title: "Success",
          message: "The Email was sent"
        });
      } catch (error) {
        console.dir(error);
      }
    },

    async refreshData() {
      return this.getActiveTransactions([this.policyNo, false]);
    },
    routeToSelect() {
      this.$router.replace({
        name: "selectScreen",
        path: `/policyholder/select`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_variables.scss";

.card {
  padding: 1rem;
  background-color: #fff;
  color: lighten(#000, 10);
  box-shadow: 0 0 3px rgba(#000, 0.5);
  margin-bottom: 2rem;
}

.policy-detail {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  scroll-margin-top: 50px;

  .can-gray {
    filter: saturate(1);
    transition: filter 500ms linear;
  }

  &.cancelled .can-gray {
    filter: saturate(0.4);

    ::v-deep .disable-in-cancel {
      pointer-events: none;
    }
  }

  &.pending-cancel .can-gray {
    filter: saturate(0.7);

    ::v-deep .disable-in-pending {
      pointer-events: none;
    }
  }
}

a {
  color: #0c5692;
  font-weight: bolder;
}

::v-deep .el-textarea {
  & .el-textarea__inner {
    @apply h-20;
  }
}
// .right-column {
//   > * {
//     margin-bottom: 1.5rem;
//   }
// }

.coverage-items {
  display: block;

  @supports not (display: -ms-grid) {
    display: grid;
    grid: auto / repeat(auto-fill, minmax(210px, 1fr));
  }
}

.tax-items {
  @supports not (display: -ms-grid) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    white-space: nowrap;
    grid-gap: 1rem;
  }

  .diff-item {
    .ie & {
      margin-right: 1rem;
    }
  }
}

.coverage-item {
  $circle-size: 35px;

  margin-right: 1.2rem;
  margin-bottom: 2rem;
  display: inline-grid;
  // grid: #{$circle-size} 1fr / #{$circle-size} 1fr;
  grid-template-columns: #{$circle-size} 1fr;
  grid-template-rows: #{$circle-size} 1fr;
  grid-gap: 10px;
  margin-top: 1rem;
  position: relative;

  .ie & {
    margin-right: 1rem;
    width: 220px;
    position: relative;
    display: flex;
    flex-flow: row wrap;
  }

  > strong {
    width: 100%;
    height: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #209686;
    color: #fff;
    box-shadow: 0 0 3px rgba(#000, 0.5);
    text-shadow: 0 0 3px rgba(#000, 0.75);
  }

  > label {
    align-self: center;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  &__content {
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    flex-flow: row;
    font-size: 0.85rem;

    > * {
      display: block;

      > * {
        display: block;

        &:not(:last-child) {
          margin-right: 1.9rem;
        }
      }
    }
  }
}

// .middle-content {
//   > * {
//     margin-bottom: 2rem;
//   }
// }

.top-header {
  &__image {
    .is-loading & {
      opacity: 0;
      transform: translateY(-20px);
    }

    &__inner {
      position: absolute;
      background-color: #122b4e;
      .theme-earthquake & {
        background-image: linear-gradient(30deg, #f9e7c6, 60%, #ffdaed);
      }
      color: #fff;
      width: 100%;
      height: 100%;

      .is-agent & {
        background-color: #0d5555;
        .theme-earthquake & {
          background-image: linear-gradient(30deg, #f9e7c6, 60%, #ffdaed);
        }
        .theme-neptune & {
          background-color: #016ba5;
        }
      }
    }
  }
}

.diff-item {
  align-items: flex-end;

  > * {
    &:nth-child(2) {
      font-size: 1.1rem;
    }
  }

  &.diff {
    label {
      color: red;
    }
  }
}

.diffed-text {
  color: red;
  font-weight: bold;
}

.timeline-item {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;

  &:hover,
  &.active {
    label {
      color: lighten(#000, 4);
    }
  }

  &.active {
    font-weight: 900;
    pointer-events: none;
  }

  label {
    cursor: inherit;
  }

  .items {
    display: flex;
    font-size: 0.7em;

    > div {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    .item {
      &-title {
        font-weight: bold;
      }
    }
  }
}

::v-deep .el-badge__content {
  box-shadow: -1px 2px 3px 0 rgba(0, 0, 0, 0.37);
}

.last-changes {
  overflow: hidden;
  width: 100%;

  > * {
    flex: 0 1 auto;
  }

  pre {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content-container {
  display: flex;
  flex: 1 0 auto;
  flex-flow: column;
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  transition: transform 450ms cubic-bezier(0, 0.84, 0.25, 1);

  opacity: 1;
  transform: translateY(0);

  .is-loading & {
    opacity: 0;
    transform: translateY(200px);
    pointer-events: none;
    position: absolute;
    height: 0;
    overflow: hidden;
  }

  > * {
    &:last-child {
      flex: 1;
    }

    &.policies {
      flex: 1 1 auto;
    }
  }

  &__title {
    display: flex;
    justify-content: flex-end;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 200;
    padding: 0 1em;
    text-align: center;

    @media screen and (min-width: 640px) {
      flex-flow: row;
      text-align: unset;
    }
    > .label {
      flex: 1 1 auto;
      position: relative;

      > p {
        visibility: hidden;
        position: absolute;
        font-size: 0.95rem;
        line-height: normal;
        width: 100%;

        .cancelled & {
          visibility: unset;
        }
      }
    }
    > .logo {
      width: 100%;
      max-width: 240px;
      .theme-earthquake & {
        max-width: 440px;
      }

      @media screen and (min-width: 640px) {
        flex: 0 1 auto;
        height: 50px;
        .theme-earthquake & {
          height: 150px;
        }
        width: auto;
        .theme-earthquake & {
          margin-bottom: -50px;
        }
      }

      > img {
        margin: auto;

        @media screen and (min-width: 640px) {
          height: 100%;
          width: auto;
        }
        filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.7));

        .theme-neptune & {
          filter: saturate(0) brightness(4);
        }
      }
    }
  }
}

.content-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  pointer-events: none;
}

.inline-block {
  display: inline-block;
}

.fs-dialog__wrapper {
  padding: 1rem;
}

::v-deep .fs-dialog {
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  // width: auto;
  margin: 0 auto;
  max-width: 1200px;

  .el-dialog__header {
    height: 50px;
    padding: 0;
    display: flex;
    z-index: 10;
    box-shadow: 0 0 5px 0 rgba(#000, 0.5);

    .el-dialog__headerbtn {
      position: relative;
      top: auto;
      right: auto;
      width: 50px;
      height: 50px;
      margin-left: auto;
    }
  }

  .el-dialog__body {
    flex: 1;
    overflow: hidden;
    padding: 0;
    position: relative;
    display: flex;

    > * {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      > * {
        flex: 1;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.cancelled {
  // pointer-events: none;
  user-select: none;
  // cursor: not-allowed;
}
::v-deep .dialog-class {
  margin-bottom: auto;
}
.payment-select-alert {
  font-size: 15px;
  margin-top: -18px;
  margin-bottom: 18px;
}
.payment-select-alert-active {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.transferModalBody {
  display: flex;
  justify-content: space-between;
  h2 {
    color: #0c5692;
  }
}
.to-card {
  border: 1px solid #209686;
  border-radius: 5px;
  width: 30%;
  padding: 10px 0px 10px 10px;
}
.from-card {
  border: 1px solid #209686;
  border-radius: 5px;
  width: 30%;
  padding: 10px 0px 10px 10px;
}
.trident-image {
  transform: rotate(90deg);
  width: 50px;
  height: 90px;
  margin: 0 auto;
}

.notifications__button {
  margin-left: 0px !important;
  margin-top: 10px;
  @media screen and (min-width: 1360px) {
    margin-left: 10px;
    margin-top: 0px;
  }
}

.overviewFull {
  width: 100% !important;
}
.overViewPartial {
  width: 700px;
}
</style>
